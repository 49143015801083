import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Jumbotron from './jumbotron'
import handleError from './handleError';
import date_format from './date_format';

async function getFeedbackList(token, trial_id){
    return fetch("/api/feedbacklist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id})
    })
        .then(data => data.json());
}

async function updateLastSeen(token, trial_id){
    let time = Date.now();
    return fetch("/api/updatefeedbackseen", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id, time})
    })
        .then(data => data.json());
}

async function deleteFeedback(token, post_id, trial_id){
    return fetch("/api/deletefeedback", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, post_id, trial_id})
    })
        .then(data => data.json());
}

async function createFeedback(token, post){
    return fetch("/api/createfeedback", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, post})
    })
        .then(data => data.json());
}

async function createFeedbackWithAttachment(token, post, file){
    const formData = new FormData();
    formData.append('token', token);
    formData.append('reply_id', post.reply_id);
    formData.append('title', post.title);
    formData.append('body', post.body);
    formData.append('trial_id', post.trial_id);
    formData.append('time', file.lastModified);
    formData.append('file', file);
    return fetch("/api/createfeedbackatt", {
        method: "POST",
        body: formData
    })
        .then(data => data.json());
}

function getHost(){
    return `${window.location.protocol}//${window.location.host === 'localhost:3000' ? 'localhost:3001' : window.location.host}`;
}

function Post({post, posts, setPosts, token, setToken, trial}){

    function del(){
        if (!window.confirm("Are you sure you want to delete this post?")) return;
        deleteFeedback(token.token, post.id, trial.id)
            .then(data => {
                if (data.err) return handleError(data.err, setToken);
                if (data.succ) setPosts(posts.filter(p => p.id != post.id));
            })
    }

    return (
        <Jumbotron>
            <h3>{post.from}: {post.title}</h3>
            <Form.Control as="textarea" value={post.body} rows={post.body.split(/\r\n|\r|\n/).length} readOnly/>
            {
                post.filename ?
                (
                    post.filename.endsWith(".jpg") || post.filename.endsWith(".JPG")|| post.filename.endsWith(".jpeg") || post.filename.endsWith(".JPEG") || post.filename.endsWith(".png")|| post.filename.endsWith(".PNG") ?
                    <img width={window.innerWidth > 1000 ? "50%" : "100%"} src={`${getHost()}/instructions/${post.filename}`} alt={`${getHost()}/instructions/${post.filename}`}/> :
                    <Button variant="link" target="_blank" href={`${getHost()}/instructions/${post.filename}`}>{post.originalname}</Button>
                ) :
                null
            }
            {
                post.time ?
                <p>{date_format(typeof post.time === "string" ? parseInt(post.time) : post.time)}</p> :
                null
            }
            {
                post.from === token.userInfo.email || trial.isAdmin ?
                <Button style={{float: "right"}} variant="primary" onClick={del}>Delete</Button>:
                null
            }
        </Jumbotron>
    )
}

function NewPost({retitle, rebody, reply_id, trial, posts, setPosts, token, setToken, setNewPost}){
    let title = useRef(null);
    let body = useRef(null);
    let file = useRef(null);

    function addPost(event){
        event.preventDefault();

        function process(data){
            if (data.err) handleError(data.err, setToken);
            else{
                setPosts(posts.concat([data.post]));
                setNewPost(undefined);
            }
        }

        let post = {
            reply_id, 
            title: title.current.value, 
            body: body.current.value,
            trial_id: trial.id,
            time: Date.now()
        }

        if (file.current.files[0])
            createFeedbackWithAttachment(token.token, post, file.current.files[0])
                .then(process)
        else
            createFeedback(token.token, post)
                .then(process)
    }

    useEffect(()=>{
        if (rebody) body.current.scrollIntoView();
    }, [posts])

    return (
        <Jumbotron>
        <Button variant="primary" style={{float: "right"}} onClick={()=>{setNewPost(undefined)}}>Back</Button>
            <h3>New post</h3>
            <Form onSubmit={addPost}>
                <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" defaultValue={retitle} ref={title}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Body</Form.Label>
                    <Form.Control as="textarea" defaultValue={rebody} rows={5} ref={body} autoFocus={rebody ? true : false}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Attachment</Form.Label>
                    <Form.Control type="file" ref={file}/>
                </Form.Group>
                <Button variant="primary" type="submit">Add post</Button>
            </Form>
        </Jumbotron>
    )
}

export default function Feedback( {trial, token, setToken, fileList, setUnseenFeedback} ){
    const [posts, setPosts] = useState([]);
    const [newPost, setNewPost] = useState(undefined)
    const bottom = useRef(null);

    useEffect(() => {
        if (fileList) setNewPost(0);
        getFeedbackList(token.token, trial.id)
            .then(data => {
                if (data.err) return handleError(data.err, setToken);
                setPosts(data.posts);
                bottom.current.scrollIntoView();
            })
        updateLastSeen(token.token, trial.id)
            .then(data => {
                if (data.err) return console.log("error updating seen feedback");
                setUnseenFeedback(0);
            })
    }, []);

    function getTitle(){
        return fileList!==undefined ? "Feedback about files" : undefined;
    }

    function getBody(){
        if (fileList===undefined) return undefined;
        return `This post is related to files ${fileList.slice(0, 3).join(', ')}${fileList.length>3 ? ", etc" : ""}.`;
    }

    return (
        <div style={{height: '80vh', overflow: 'auto'}}>
            {posts.filter(p => p.reply_id===0).map((p, i) => (
                <div key={i}>
                    <Post post={p} posts={posts} setPosts={setPosts} token={token} setToken={setToken} trial={trial}/>
                    <div style={{paddingLeft: "2em"}}>
                        {posts.filter(p2 => p2.reply_id === p.id).map((p, i) => (
                            <Post key={i} post={p} posts={posts} setPosts={setPosts} token={token} setToken={setToken} trial={trial}/>
                        ))}
                        {
                            trial.isGuest ?
                            null :
                            newPost === p.id ?
                            <NewPost retitle={`RE: ${p.title}`} reply_id={p.id} trial={trial} posts={posts} setPosts={setPosts} setNewPost={setNewPost} token={token} setToken={setToken}/> :
                            <Button style={{marginBottom: "2em", marginTop: "-1em"}} type="primary" onClick={()=>{setNewPost(p.id)}}>Reply</Button>
                        }
                    </div>
                </div>
            ))}
            {
                trial.isGuest ?
                <div ref={bottom}></div> :
                newPost === 0 ?
                <NewPost retitle={getTitle()} rebody={getBody()} reply_id={0} trial={trial} posts={posts} setPosts={setPosts} setNewPost={setNewPost} token={token} setToken={setToken}/> :
                <Button ref={bottom} type="primary" onClick={()=>{setNewPost(0)}}>Give feedback</Button>
            }
        </div>
    )
}
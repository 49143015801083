import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import arte from './images/arte.png';
import shyn from './images/shyn.png';
import tocsen from './images/img1.jpg';
import pranyu from './images/pranyu.png';

function UseCase({image, body}){
    return (
        <Card style={{margin:"10px"}}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                {body}
            </Card.Body>
        </Card>
    )
}

export default function LivingLabTrials(){
    return (
        <div>
            <h3 style={{marginTop:"10px"}}>LivingLab.cloud for Living Lab trials</h3>
            <h5 style={{marginTop:"20px"}}>
            What Makes LivingLab.cloud Special for Designing and Performing Living Lab Trials            </h5>
            <ul>
                <li><p><b>LivingLab.cloud</b> has been designed and developed by Digital Spaces Living Lab (DSLL) - one of the leading living labs in Europe. In the last 12 years, DSLL has conducted over 45 large-scale technology validation trials in the fields of smart cities, smart agriculture, mobile apps, wearables, location-based services (LBS), games, digital media, etc.</p></li>
                <li><p><b>LivingLab.cloud</b> supports designing and carrying out complex Living Lab trials by sending trial instructions to test users and collecting user feedback. Other features supported include data-related feedback and discussions, screenshots, live photo/video feedback sharing and comments.</p></li>
                <li><p>Test participants in Living Lab trials, wearing various devices and sensors, apart from the new product which is tested and validated, gather rich and diverse multi-sensor data from all of them in the cloud, which is automatically ingested by data importers, converted in suitable formats to be jointly visualised and combined (fused) by data converters and processed and analysed by data processors, all provided by <b>LivingLab.cloud.</b></p></li>
                <li><p><b>LivingLab.cloud</b> is a platform designed and implemented specifically for multi-sensor data gathering, analysis and visualisation in the cloud.</p></li>
                <li><p><b>LivingLab.cloud</b> offers an extremely high level of automation of multi-sensor data import, conversion, organisation, and processing in the cloud, thus saving considerable time and money.</p></li>
                <li><p><b>LivingLab.cloud</b> subscription plans come with a large and fast increasing collection of cloud importers, convertors, and processors for multi-sensor data, which can be combined in many ways.</p></li>
                <li>
                    <p><b><b>LivingLab.cloud</b> features which can be especially useful in Living Lab trials include:</b></p>
                    <ul>
                        <li><p>Advance geofences, zones, tracking, etc.</p></li>
                        <li><p>Communication with various wearable sensor and devices via WiFi, 5G and LoRaWAN</p></li>
                        <li><p>Various mathematical metrics to compare location tracks when developing or validating the performance of novel wearable trackers</p></li>
                    </ul>
                </li>
                <li>
                    <p><b>LivingLab.cloud integrations for wearables include:</b></p>
                    <ul>
                        <li><p><a target="_blank" href="https://www.strava.com/">Strava</a> integration (for routes and location data);</p></li>
                        <li><p><a target="_blank" href="https://phyphox.org/">Phyphox</a> integration (for smarphone sensor data);</p></li>
                        <li><p><a target="_blank" href="https://www.apple.com/ios/health/">Apple Health</a> integration (for Apple Watch and other data);</p></li>
                        <li><p><a target="_blank" href="https://gopro.com/">GoPro</a> integration (for GoPro videos and telemetry data)</p></li>
                        <li><p>DJI drone video and telemetry integration – coming soon</p></li>
                        <li><p><a target="_blank" href="https://imagga.com/">Imagga</a> integration (for photo recognition) – coming soon</p></li>
                    </ul>
                </li>
            </ul>
            <h5 id="usecases" style={{marginTop:"50px"}}>Customer and Partner Use Cases</h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={tocsen} body={
                        <div>
                            <Card.Text>
                            Living Lab trials with mountain bikers for a whole year of the Tocsen crash wearable sensor, developed by <a href="https://en.tocsen.com/" target="_blank">Tocsen GmbH</a>, Germany, as part of the Galileo Incubation by DSLL.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={shyn} body={
                        <div>
                            <Card.Text>
                            Trials in LivingLab.cloud with mountain guides and rescuers of the novel <a target="_blank" href="https://shyn.blue/">Shyn positioning sensor and tracker</a>, developed by the Belgian company <a href="https://www.epic.blue/"  target="_blank">Epic Blue</a>, which works indoors and outdoors (also in GNSS denied areas).
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <UseCase image={arte} body={
                        <div>
                            <Card.Text>
An international team of aerospace engineers (<a href="https://artemapp.com/"  target="_blank">ArteMapp</a>) developed an affordable and practical terrain mapping solution that enables fast and frequent surveying of a selected area by merely walking with a mobile device. They used LivingLab.cloud for data gathering and topographical model computation and visualisation.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={pranyu} body={
                        <div>
                            <Card.Text>
                            <a href="https://www.panda-insight.com/en">Panda Insight</a>, together with DSLL, carried out a Living Lab trial in 2022 with test participants wearing their iPhones, Apple Watches and the <a href="https://pranyu.com/en/">Pranyu app</a>. LivingLab.cloud was used to automatically gather, visualise and analyse multi-sensor data such as: stress data estimated by Panda Insight’s models (yellow circles, reported stress (blue circles) by the test users via surveys in the app sent at main locations during the day, and users’ location tracks.                            
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>
            <h5 style={{marginTop:"50px"}}>Pricing</h5>
            <p><b>If you plan to design, organise or perform a Living Lab trial with test users, and want to rapidly do this at a much lower cost, try and use LivingLab.cloud by </b><a href="/pricing/livinglabs">submitting a subscription plan request</a>.</p>
        </div>
    )
}
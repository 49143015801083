import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import qs from 'qs';

async function resetPassword(credentials){
    return fetch('/api/reset', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

export default function Reset({ setToken }) {
    const emailEl = useRef(null);
    const passwordEl = useRef(null);
    const password2El = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    let params = qs.parse(location.search, {ignoreQueryPrefix: true});

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await resetPassword(
            {
                email: emailEl.current.value,
                registerToken: params.token,
                password: passwordEl.current.value,
                password2: password2El.current.value
            }
        )
        if (response.err) alert(response.err);
        else{
            setToken(response.token);
            navigate('./dashboard');
        }
    }

    return (
        <div>
            <h2>Reset password</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control ref={emailEl} type="email" value={params.email} readOnly/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control ref={passwordEl} type="password"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control ref={password2El} type="password"/>
                </Form.Group>
                <Button variant="primary" type="submit">Reset password</Button>
            </Form>    
        </div>
    );
}

Reset.propTypes = {
    setToken: PropTypes.func.isRequired
};
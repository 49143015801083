import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import airlief from './images/airlief.png';
import arte from './images/arte.png';
import farm from './images/farm.png';
import tree from './images/tree.png';
import sat from './images/isla-de-armona-algarve-portugal-sentinel-2-roi-map.png'
import geotiff from './images/zanzibar-satellite-drone-photos-1.png'
import keyframes from './images/greece-dji-mini2-drone-keyframes.png'

function UseCase({image, body}){
    return (
        <Card style={{margin:"10px"}}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                {body}
            </Card.Body>
        </Card>
    )
}

export default function EarthObservation(){
    return (
        <div>
            <h3 style={{marginTop:"10px"}}>LLcloud® for Earth Observation (EO) Applications</h3>
            <h5 style={{marginTop:"20px"}}>
            What Makes LLcloud Special for Developing Earth Observation Applications            </h5>
            <ul>
                <li><p>LLcloud is the new multi-sensor cloud for Earth Observation at different scales!</p></li>
                <li><p>LLcloud is a platform designed and implemented specifically for multi-sensor data gathering, analysis and visualisation in the cloud.</p></li>
                <li><p>It is one of the few platforms in the world which allows joint analysis of multi-sensor ground (IoT, wearable and platform), aerial (drone) and space (GPS and Galileo location and Copernicus EO) data.</p></li>
                <li><p>LLcloud offers an extremely high level of automation of multi-sensor data import, conversion, organisation and processing in the cloud, thus saving considerable time and money.</p></li>
                <li><p>LLcloud can be used for both data gathering from novel space and ground data solutions and applications, for design and development of downstream space data applications, but also for Living Lab trials with test users, who can then provide feedback via the same platform while testing and validating such applications. </p></li>
                <li><p>LLcloud subscription plans come with a large and fast increasing collection of cloud data importers, convertors and processors for Earth Observation, IoT, wearable and smart city apps, which can be combined in many different ways.</p></li>
                <li>
                    <p><b>LLcloud features for Earth Observation applications include:</b></p>
                    <ul>
                        <li><p>Comprehensive world map search and region of interest (RoI) selection;</p></li>
                        <li><p>Automated download of Copernicus Sentinel data (raw or processed) from the Copernicus Data Space Ecosystem portal into LLcloud for a selected RoI (up to certain sizes and data volumes), where other multi-sensor data (ground or aerial) is already available or will be gathered, for joint analysis and visualisation;</p></li>
                        <li><p>Drone images (.GeoTiff and .JPG), videos (.MP4) and flight path
(.GPX) visualisation on a map. Extraction of key frames from 
drone videos for further exploration. Overlays of satellite and 
drone images for joint analysis at different scales, also with 
ground-based photos and other geo-located data. Integration 
with <a href="https://airdata.com/" target="_blank">AirData portal</a> for automated 
flightpath and telemetry data transfer to LLcloud;</p></li>
                        <li><p>Advanced geofences, zones, tracking, etc.</p></li>
                        <li><p>Real-time tracking and movement analysis of ships (via AIS stations) and trucks (coming soon)</p></li>
                        <li><p>Communication with various IoT sensors and devices via WiFi, 5G and LoRaWAN.</p></li>
                    </ul>
                </li>
                <li>
                    <p><b>LLcloud integrations for Earth Observation applications include:</b></p>
                    <ul>
                        <li><p><a target="_blank" href="https://dataspace.copernicus.eu/">Copernicus Data Space Ecosystem</a> integration (for access to data and services from the Copernicus Sentinel missions and more on the Earth’s land, oceans, and atmosphere) for EO from space;</p></li>
                        <li><p>DJI drone images, video and telemetry integration for EO from the air;</p></li>
                        <li><p>Global ship location and movement (via AIS stations)</p></li>
                        <li><p><a target="_blank" href="https://www.strava.com/">Strava</a> integration (for routes and location data);</p></li>
                        <li><p>DJI drone video and telemetry integration (coming soon) for EO from the air;</p></li>
                        <li><p>easy capture and automated LLcloud upload and organisation of geo-located and tagged photos and videos (coming soon) via the photoSpot mobile app for <a href="https://play.google.com/store/apps/details?id=com.eym.photospotapp" target="_blank">Android</a> and <a href="https://apps.apple.com/us/app/photospotapp/id1539906598#?platform=iphone" target="_blank">iOS</a> for ground-based EO.</p></li>
                    </ul>
                </li>
            </ul>
            <h5 id="usecases" style={{marginTop:"50px"}}>Customer and Partner Use Cases</h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={arte} body={
                        <div>
                            <Card.Text>
An international team of aerospace engineers (<a href="https://artemapp.com/"  target="_blank">ArteMapp</a>) developed an affordable and practical terrain mapping solution that enables fast and frequent surveying of a selected area by merely walking with a mobile device. They used LLcloud for data gathering and topographical model computation and visualisation.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={farm} body={
                        <div>
                            <Card.Text>
                            One of the leading precision farming University research groups in Europe and its spin-out company, use LLcloud to gather multi-sensor (image, video, location tracks) geolocated crop and weed photo datasets and monitor plant diseases. LLcloud can be used in various smart farming applications. 
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <UseCase image={sat} body={
                        <div>
                            <Card.Text>
                            Some of our clients and partners have been using Copernicus Sentinel 2 images (RGB, SWIR, NDVI, etc.) in various Earth Observation applications related to tourism, smart cities, and smart agriculture. The example shows a visible Sentinel 2 image RoI of Ilha da Armona in the Algarve, Portugal.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={tree} body={
                        <div>
                            <Card.Text>
                            <a href="https://ednodarvo.io/" target="_blank">Едно Дърво (One Tree)</a> is a joint initiative of landscape architects and software engineers to create interactive maps of trees in the city. LLcloud is used by their team to compare Copernicus Sentinel images (NDVI etc) of green spaces in the city to ground-based tree locations and photos to improve, speed up and scale the process of mapping and monitoring trees in cities around the world.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <UseCase image={geotiff} body={
                        <div>
                            <Card.Text>
<b>LLcloud can visualise Drone/UAV images in GeoTIFF format jointly with 
satellite images and other geolocated data.</b> The example above shows an 
UAV image (right) by Federico Debetto,  Stone Town Test, Zanzibar, DJI FC6310S,
2cm resolution, from <a href="https://map.openaerialmap.org/" target="_blank">OpenAerialMap</a> overlaid 
on a visible satellite image of 10m resolution
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={keyframes} body={
                        <div>
                            <Card.Text>
<b>LLcloud can extract flightpath and telemetry data from DJI drone flights 
using the <a href="https://airdata.com/" target="_blank">Airdata portal</a> integration.</b> Additionally, it 
can <b>extract key frames from the recorded DJI drone video at key 
locations</b> (or using other criteria) and display them along the drone flight path 
for further inspection, as in the example to the right. 
Also, add/change the following in the same web page:
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>
            <h5 style={{marginTop:"50px"}}>Pricing</h5>
            <p><b>If you are developing or testing a new EO application where you want to combine space data with ground-based (IoT/wearable) or air data</b> and want to rapidly do this at a much lower cost, try and use LLcloud by <a href="/pricing/earthobservation">submitting a subscription plan request.</a></p>
        </div>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import { Button, Form, Nav, Table } from 'react-bootstrap';
import handleError from './handleError';

async function getSubscriptions(token){
    return fetch("superuser/listsubscriptions", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token})
    })
        .then(data => data.json());
}

async function deleteSubscription(token, id){
    return fetch("superuser/deletesubscription", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, id})
    })
        .then(data => data.json());
}

async function updateSubscription(token, subscription){
    return fetch("superuser/updatesubscription", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, subscription})
    })
        .then(data => data.json());
}

function Pending({token, subscriptions, reload}){
    function approve(subscription){
        subscription.approved = true;
        updateSubscription(token.token, subscription).then(data => {reload()})
    }

    function del(id){
        if (window.confirm("Are you sure you want to delete this subscription. This cannot be undone.")){
            deleteSubscription(token.token, id).then(data => {reload()})
        }
    }

    function isPending(s){
        return !s.approved
    }

    return (
        <div>
            <h3>Pending</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>email</th>
                        <th>Company</th>
                        <th>Usecase</th>
                        <th>Sensors</th>
                        <th>Plan</th>
                        <th>Requested duration</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {subscriptions.filter(isPending).map(s => (
                        <tr>
                            <td>{s.name}</td>
                            <td>{s.surname}</td>
                            <td>{s.email}</td>
                            <td>{s.company}</td>
                            <td>{s.usecase}</td>
                            <td>{s.sensors}</td>
                            <td>{s.plan}</td>
                            <td>{s.duration}</td>
                            <td><Button variant="primary" onClick={()=>{del(s._id)}}>Delete</Button><Button variant="primary" onClick={()=>{approve(s)}}>Approve</Button></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

function subStart(s){
    return ( 
        s.start ? 
        new Date(s.start).toISOString().split('T')[0] : 
        new Date().toISOString().split('T')[0]
    )
}

function subEnd(s){
    let today = new Date();
    let defaultDate;
    if (s.duration === '6m')
        defaultDate = new Date(today.setMonth(today.getMonth()+6))
    else 
        defaultDate = new Date(today.setYear(today.getFullYear()+1));
    return (
        s.end ? 
        new Date(s.end).toISOString().split('T')[0] : 
        defaultDate.toISOString().split('T')[0]
    )
}

function Approved({token, subscriptions, reload}){
    const start = useRef([]);
    const end = useRef([]);
    const paid = useRef([]);

    useEffect(()=>{
        start.current = start.current.slice(0, subscriptions.length);
        end.current = end.current.slice(0, subscriptions.length);
        paid.current = paid.current.slice(0, subscriptions.length);
    }, [subscriptions]);

    function isApproved(s){
        let now = new Date();
        let notActive = !s.start || !s.end || now > new Date(s.end) || now < new Date(s.start); 
        return s.approved && notActive;
    }

    function activate(subscription, i){
        subscription.start = start.current[i].value;
        subscription.end = end.current[i].value;
        subscription.paid = paid.current[i].value;
        updateSubscription(token.token, subscription).then(data => {reload()})
    }
    
    function del(id){
        if (window.confirm("Are you sure you want to delete this subscription. This cannot be undone.")){
            deleteSubscription(token.token, id).then(data => {reload()})
        }
    }

    return (
        <div>
            <h3>Approved</h3>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>email</th>
                        <th>Company</th>
                        <th>Plan</th>
                        <th>Requested duration</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Paid/Unpaid</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {subscriptions.filter(isApproved).map((s, i) => (
                        <tr key={i}>
                            <td>{s.name}</td>
                            <td>{s.surname}</td>
                            <td>{s.email}</td>
                            <td>{s.company}</td>
                            <td>{s.plan}</td>
                            <td>{s.duration}</td>
                            <td><Form.Control defaultValue={subStart(s)} type="date" ref={el => start.current[i] = el}/></td>
                            <td><Form.Control defaultValue={subEnd(s)} type="date" ref={el => end.current[i] = el}/></td>
                            <td>
                                <Form.Select defaultValue={s.paid ? s.paid : "paid"} ref={el => paid.current[i] = el}>
                                    <option value="paid">Paid</option>
                                    <option value="unpaid">Unpaid</option>
                                </Form.Select>
                            </td>
                            <td>
                                <Button variant="primary" onClick={()=>{activate(s, i)}}>Activate</Button>
                                <Button variant="primary" onClick={()=>{del(s._id)}}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

function Active({token, subscriptions, reload}){
    const start = useRef([]);
    const end = useRef([]);
    const paid = useRef([]);

    function isActive(s){
        let now = new Date();
        let notActive = s.start && s.end && now <= new Date(s.end) && now >= new Date(s.start); 
        return s.approved && notActive;
    }

    function activate(subscription, i){
        subscription.start = start.current[i].value;
        subscription.end = end.current[i].value;
        subscription.paid = paid.current[i].value
        updateSubscription(token.token, subscription).then(data => {reload()})
    }
    
    useEffect(()=>{
        start.current = start.current.slice(0, subscriptions.length);
        end.current = end.current.slice(0, subscriptions.length);
    }, [subscriptions]);

    return (
        <div>
            <h3>Active</h3>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>email</th>
                        <th>Company</th>
                        <th>Plan</th>
                        <th>Requested duration</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Paid/Unpaid</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {subscriptions.filter(isActive).map((s, i) => (
                        <tr key={i}>
                            <td>{s.name}</td>
                            <td>{s.surname}</td>
                            <td>{s.email}</td>
                            <td>{s.company}</td>
                            <td>{s.plan}</td>
                            <td>{s.duration}</td>
                            <td><Form.Control defaultValue={subStart(s)} type="date" ref={el => start.current[i] = el}/></td>
                            <td><Form.Control defaultValue={subEnd(s)} type="date" ref={el => end.current[i] = el}/></td>
                            <td>
                                <Form.Select defaultValue={s.paid ? s.paid : undefined} ref={el => paid.current[i] = el}>
                                    <option value="paid">Paid</option>
                                    <option value="unpaid">Unpaid</option>
                                </Form.Select>
                            </td>
                            <td><Button onClick={()=>{activate(s, i)}}>Update dates</Button></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default function Subscriptions({token, setToken}){
    const [tab, setTab] = useState("pending");
    const [subscriptions, setSubscriptions] = useState([]);

    function reload(){
        getSubscriptions(token.token)
            .then(data => {
                console.log(data);
                if (data.err) {
                    return handleError(data.err, setToken);
                }
                setSubscriptions(data.subscriptions)
            })
    }

    useEffect(()=>{
        reload();
    }, [])

    return (
        <div>
            <Nav variant="tabs" activeKey={tab} onSelect={key => setTab(key)}>
                <Nav.Item>
                    <Nav.Link eventKey="pending">Pending</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="approved">Approved</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="active">Active</Nav.Link>
                </Nav.Item>
            </Nav>
            {tab === "pending" ? <Pending token={token} subscriptions={subscriptions} reload={reload}/>: null}
            {tab === "approved" ? <Approved token={token} subscriptions={subscriptions} reload={reload}/>: null}
            {tab === "active" ? <Active token={token} subscriptions={subscriptions} reload={reload}/>: null}
        </div>
    )
}
import React from 'react';

export default function AboutLL(){
    return (
        <div>
            <p style={{marginTop:"2em"}}>
            <b>LivingLab.cloud - the multi-sensor living lab cloud,</b> is a cloud software platform for automated multi-sensor data gathering, analysis and visualisation, and Living Lab trials with test users of novel sensors, devices, apps and services.
            </p>
            <p><b>The platform supports live multi-sensor data gathering and organisation in the cloud and Living Lab trials with real users.</b></p>
            <p><b>LivingLab.cloud supports designing and carrying out complex Living Lab trials by sending trial instructions to test users and collecting user feedback. Other features supported include data-related feedback and discussions, screenshots, live photo/video feedback sharing and comments. </b></p>
            <p><b>Test participants wearing various devices and sensors, apart from the new product which is tested and validated, gather rich and diverse multi-sensor data from all of them in the cloud, which is automatically ingested by data importers, converted in suitable formats to be jointly visualised and combined (fused) by data converters and processed and analysed by data processors, all provided by LivingLab.cloud.</b> Additional data from ground-based IoT sensors, aerial (drone) and space (satellite) data can be also gathered and used in <b>LivingLab.cloud.</b></p>
            <p><b>LivingLab.cloud provides many ready-made cloud data importers, converters and processors.</b></p>
            <p><b>Data importers</b> allow automated data upload from smartphone sensors (via the <a href="https://phyphox.org/" target="_blank">Phyphox</a> app) and location tracks (via the <a href="https://www.strava.com/" target="_blank">Strava</a> app), smartwatches, geo-tagged photos (via the photoSpot app for <a href="https://play.google.com/store/apps/details?id=com.eym.photospotapp" target="_blank">Android</a> and <a href="https://apps.apple.com/us/app/photospotapp/id1539906598#?platform=iphone" target="_blank">iOS</a>), GoPro cameras, Air Quality (AQ) and other IoT sensors, drones (coming up in Q1 2023), etc.</p>
            <p><b>Data converters</b> transform data to other common formats, such as .KML, .GPX, .CSV, etc.</p>
            <p><b>Data processors</b> analyse the gathered multi-sensor data directly in LLcloud. Some data processors allow measuring global and local similarity between location tracks, finding track segments with bad or missing GNSS data, definining geofences and zones and checking location against them, calculating exposure along a track to various air pollutants measured by ground-based stations, etc. Others implement communication with various wearable and fixed devices via WiFi, 5G and LoRaWAN. LLcloud processors can be combined in data processing pipelines.</p>
            <p><b>LivingLab.cloud smart visualisation tools</b> show various multi-sensor data views - table, tree, map-based, detailed view. Layers of different geo-located data can be visualised and analysed simultaneously.</p>
            <p><b>If you want design and run a Living Lab trial with real users</b> of novel wearables, IoT sensors or networks, mobile apps, smart city applications, or location-based services using LivingLab.cloud, please <a href="/pricing/livinglabs">submit a subscription plan request</a>.</p>
            <p>For any questions, please <a href="/contact">contact us</a>.</p>
        </div>
    )
}
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import world1 from './images/ports-world1.png';
import world2 from './images/ports-world2.png';
import portanalytics1 from './images/port-analytics1.png';
import portanalytics2 from './images/port-analytics2.png';

function UseCase({image, body}){
    return (
        <Card style={{margin:"10px"}}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                {body}
            </Card.Body>
        </Card>
    )
}

export default function PortAnalytics(){
    return (
        <div>
            <h3 style={{marginTop:"10px"}}>Multi-Sensor Port Analytics Business Solution</h3>

            <p style={{marginTop:"20px", fontSize:"1.1em"}}><b>LLcloud’s multi-sensor port analytics business solution helps port operators monitor vessel time spent in port and charge port fees accordingly and logistics companies monitor and optimise the (off)loading of containers from/to ships by providing real-time analytics of such activities in port areas, based on multi-sensor data, such as AIS data vessel tracking, IoT and other sensor container movements and data.</b></p>

            <h5 id="usecases" style={{marginTop:"50px"}}>Port Analytics Around the World</h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={world1} body={
                        <div>
                            <Card.Text>
                             Our solution monitors vessel movements and maritime trade and traffic at up to 3500 of ports around the world using Automatic Identification System (AIS) station data and port locations.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={world2} body={
                        <div>
                            <Card.Text>
                            These ports are classified according to their size and other characteristics but our multi-sensor port analytics solution is aimed primarily at mid-sized and big ports.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>



            <h5 id="usecases" style={{marginTop:"50px"}}>Multi-Sensor Port Analytics Solution</h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={portanalytics1} body={
                        <div>
                            <Card.Text>
                            Our solution provides analytics of the number of ships entering and exiting a port using AIS and port boundary data. The example shows real-times tracking and analytics of vessels in the ports of Gibraltar and Algeciras (Spain).
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={portanalytics2} body={
                        <div>
                            <Card.Text>
                             It calculates and displays advanced analytics for ports, like the list of ships in the port area and how much time each one has spent in the port area or regions of it (right), as well as for individual ships (left). The example shows analytics of vessels in the port of Genoa (Italy).
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>


            <h5 style={{marginTop:"50px"}}>LLcloud's Multi-Sensor Port Analytics Main Features</h5>
            <ul>
                <li><p>For port operators, easily customise the solution with your own port boundaries, maps and zones.</p></li>

                <li><p>Track in real-time vessels coming in and out of the port area and in moving to different zones.</p></li>

                <li><p>For each vessel, provide detailed info and the time it has entered and left the port, etc.</p></li>

                <li><p>Generate daily, weekly, monthly and yearly analytics for the whole port showing all the vessels that have been in port (and in different zones) for the specified period.</p></li>

                <li><p>Add other platform and multi-sensor data from satellites, drones, IoT sensors and cameras installed at the port, and even wearables used by port staff.</p></li>
            </ul>


            <h5 style={{marginTop:"50px"}}>LLcloud's Multi-Sensor Port Analytics Main Benefits</h5>
            <ul>
                <li><p>Save time and money having accurate up-to-date vessel analytics for the port area and different zones.</p></li>

                <li><p>No software needs to be installed on your servers nor physical infrastructure installed in the port area to track vessels and obtain analytics, as it is a cloud solutions working globally.</p></li>

                <li><p>Automate your administrative processes with reports and statistics generated automatically, showing the vessels that have been in port for  specified periods.</p></li>

                <li><p>Easily customise the solution with your own port boundaries, maps and zones in a matter of hours.</p></li>

                <li><p>If you already have additional sensors (IoT, cameras, etc) installed in the port area, add data from them to your port analytics solution to obtain a 24/7, more comprehensive and accurate picture of what is going on and make informed better decisions. On specific occasions (natural disasters, major accidents), request and use also additional on-demand satellite images of the port and nearby areas.</p></li>
            </ul>


            <h5 style={{marginTop:"50px"}}>Pricing</h5>
            <p>If you are a port operator or a logistics company who wants to optimise the way you operate and move cargo and containers faster using multi-sensor data, please <a href="/contact">contact us</a> describing your needs.</p>
        </div>
    )
}

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Gallery from './gallery';
import airlief from './images/airlief.png';
import arte from './images/arte.png';
import shyn from './images/shyn.png';
import tocsen from './images/img1.jpg';
import cat from './images/cat.png';
import pranyu from './images/pranyu.png';

function UseCase({image, body}){
    return (
        <Card style={{margin:"10px"}}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                {body}
            </Card.Body>
        </Card>
    )
}

export default function Wearables(){
    return (
        <div>
            <h3 style={{marginTop:"10px"}}>LLcloud® for Wearables</h3>
            <h5 style={{marginTop:"20px"}}>
            What Makes LLcloud Special for Designing, Developing and Testing Wearables
            </h5>
            <ul>
                <li><p>LLcloud is a platform designed and implemented specifically for multi-sensor data gathering, analysis and visualisation in the cloud.</p></li>
                <li><p>It is one of the few platforms which allows joint analysis of ground wearable and fixed sensor data.</p></li>
                <li><p>LLcloud offers an extremely high level of automation of multi-sensor data import, conversion, organisation and processing in the cloud, thus saving considerable time and money.</p></li>
                <li><p>LLcloud can be used for both data gathering from novel space and ground data solutions and applications, but also for Living Lab trials with test users, who can then provide feedback via the same platform. </p></li>
                <li><p>LLcloud subscription plans come with a large and fast increasing collection of cloud data importers, convertors and processors for wearable, which can be combined in many ways.</p></li>
                <li>
                    <p><b>LLcloud features for wearable applications include:</b></p>
                    <ul>
                        <li><p>Advance geofences, zones, tracking, etc.</p></li>
                        <li><p>Various mathematical metrics to compare location tracks when developing or validating the performance of novel wearable trackers</p></li>
                        <li><p>Communication with various wearable sensor and devices via WiFi, 5G and LoRaWAN</p></li>
                        <li><p>Communication with Movesense sensors to design, develop and test novel wearables</p></li>
                    </ul>
                </li>
                <li>
                    <p><b>LLcloud integrations for wearables include:</b></p>
                    <ul>
                        <li><p><a target="_blank" href="https://www.strava.com/">Strava</a> integration (for routes and location data);</p></li>
                        <li><p><a target="_blank" href="https://phyphox.org/">Phyphox</a> integration (for smarphone sensor data);</p></li>
                        <li><p><a target="_blank" href="https://www.apple.com/ios/health/">Apple Health</a> integration (for Apple Watch and other data);</p></li>
                        <li><p><a target="_blank" href="https://gopro.com/">GoPro</a> integration (for GoPro videos and telemetry data)</p></li>
                        <li><p><a href="/integrated-platforms/movesense">LLcloud+Movesense</a> integration (for developing and validating novel wearables)</p></li>
                        <li><p><a target="_blank" href="https://imagga.com/">Imagga</a> integration (for photo recognition) – coming soon</p></li>
                    </ul>
                </li>
            </ul>
            <h5 id="usecases" style={{marginTop:"50px"}}>Selected Customer and Partner Use Cases</h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={shyn} body={
                        <div>
                            <Card.Text>
                            Trials in LLcloud with mountain guides and rescuers of the novel <a target="_blank" href="https://shyn.blue/">Shyn positioning sensor and tracker</a>, developed by the Belgian company <a href="https://www.epic.blue/"  target="_blank">Epic Blue</a>, which works indoors and outdoors (also in GNSS denied areas).
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={tocsen} body={
                        <div>
                            <Card.Text>
                            Living Lab trials with mountain bikers for a whole year of the Tocsen crash wearable sensor, developed by <a href="https://en.tocsen.com/" target="_blank">Tocsen GmbH</a>, Germany, as part of the Galileo Incubation by DSLL.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <UseCase image={pranyu} body={
                        <div>
                            <Card.Text>
                            <a href="https://www.panda-insight.com/en">Panda Insight</a>, together with DSLL, carried out a Living Lab trial in 2022 with test participants wearing their iPhones, Apple Watches and the <a href="https://pranyu.com/en/">Pranyu app</a>. LLcloud was used to automatically gather, visualise and analyse multi-sensor data such as: stress data estimated by Panda Insight’s models (yellow circles, reported stress (blue circles) by the test users via surveys in the app sent at main locations during the day, and users’ location tracks.                            
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={cat} body={
                        <div>
                            <Card.Text>
                            LLcloud is being used as a smart backend for a novel wearable cat tracker for city use developed by a German company, which works in hybrid mode and does the tracking in the cloud (LLcloud) in safe areas and on the device when approaching and in danger zones.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>
            <h5 style={{marginTop:"50px"}}>Pricing</h5>
            <p><b>If you are designing, developing or testing a new wearable and want to rapidly do this at a much lower cost, try and use LLcloud by </b><a href="/pricing/wearables">submitting a subscription plan request</a>.</p>
        </div>
    )
}
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, ButtonGroup, ButtonToolbar, Image, Card, Dropdown, DropdownButton, Form, FormControl, InputGroup, Table, Row, Col, Modal} from 'react-bootstrap';
import handleError from './handleError';
import date_format from './date_format';
import blue_marker from "./images/blue-marker.png";
import MapView from './mapview';
import Jumbotron from './jumbotron'
import getDistance from 'geolib/es/getPreciseDistance';

function getFiles(token, trial_id, cb){
    // return fetch("/api/fileslist", {
    //     method: "POST",
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({token, trial_id})
    // })
    //     .then(data => data.json());
    let host = window.location.host === 'localhost:3000' ? 'localhost:3001' : window.location.host;
    let url = `${window.location.protocol==='https:' ? 'wss' : 'ws'}://${host}/api/fileslist`;
    const ws = new WebSocket(url);
    ws.onopen = event => {
        ws.send(JSON.stringify({token, trial_id}))
    }
    ws.onmessage = event => {
        let data = JSON.parse(event.data);
        cb(data);
    }
}

async function getProcessors(token){
    return fetch("/api/processorlist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token})
    })
        .then(data => data.json());
}

async function updateLastSeen(token, trial_id){
    let time = Date.now();
    return fetch("/api/updatefilesseen", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id, time})
    })
        .then(data => data.json());
}

function download(token, files){
    return fetch("/api/download", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, files})
    })
        .then(data => data.json());
}

function deleteFiles(token, trial_id, files){
    return fetch("/api/deletefiles", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id, files})
    })
        .then(data => data.json());
}

function updateUserView(token, trial_id, view, grid){
    let obj = 
        view === undefined ?
        {token, trial_id, grid} :
        {token, trial_id, view};
    return fetch("/api/updateview", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    })
        .then(data => data.json());
}

function getZipLink(code){
    return `https://eapi.pcloud.com/getpubzip?code=${code}`;
}

function getFileLink(f){
    if ((f.filename.endsWith(".txt") || f.filename.endsWith(".csv")) && f.size < 2000)
        return `https://eapi.pcloud.com/getpubtextfile?code=${f.pubcode}&contenttype=text`;
    return f.publink;
}

function processFiles(token, trial_id, files, processor_name, processor_option, processor_settings, cb){
    let host = window.location.host === 'localhost:3000' ? 'localhost:3001' : window.location.host;
    let url = `${window.location.protocol==='https:' ? 'wss' : 'ws'}://${host}/api/process`;
    const ws = new WebSocket(url);
    ws.onopen = event=>{
        ws.send(JSON.stringify({token, trial_id, files, processor_name, processor_option, processor_settings}))
    }
    ws.onmessage = event => {
        let data = JSON.parse(event.data);
        cb(data);
        ws.close();
    }
}

function uploadToCloud(token, trial_id, sensor, file, pendingUploads, setPendingUploads, cb){
    const formData = new FormData();
    formData.append('token', token);
    formData.append('trial_id', trial_id);
    formData.append('sensor', sensor);
    formData.append('mtime', file.lastModified);
    formData.append('file', file);
    let xhr = new XMLHttpRequest();

    // your url upload
    xhr.open('post', '/api/uploadtocloud', true);

    xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
            let percentage = (e.loaded / e.total) * 100;
            let progress = percentage === 100 ? "Uploaded! Processing..." : `${percentage.toFixed(2)}%`
            let newPending = pendingUploads.filter(upload => upload.filename != file.name);
            newPending.push({filename:file.name, progress});
            newPending.sort()
            setPendingUploads(newPending)
        }
    };

    xhr.onerror = (e) => {
        cb(e);
    };
    xhr.onreadystatechange = () => {
        console.log(xhr.readyState)
        let newPending = pendingUploads.filter(upload => upload.filename != file.name)
        setPendingUploads(newPending)
        if (xhr.readyState === 4) {
            cb(null, JSON.parse(xhr.response))
        }
    }

    xhr.send(formData);
}

function File({ind, file, checked, setChecked}){
    let [isChecked, setIsChecked] = useState(checked[file.fileid] ? true : false)

    useEffect(() => {
        setIsChecked(checked[file.fileid] ? true : false);
    }, [checked]);

    return (
        <tr>
            <td><Form.Check checked={isChecked} onChange={event => {let c = checked; c[file.fileid]=event.currentTarget.checked; setChecked(c)}}/></td>
            <td>{file.filename}</td>
            <td>{file.user}</td>
            <td>{file.sensor}</td>
            <td>{date_format(file.modified)}</td>
            <td>{file.size}</td>
        </tr>
    )
}

function TableView({files, checked, setChecked, perPage, setPerPage, page, setPage, sort, setSort}){
    
    const showMetadata = files.some(f => f.metadata?.lat);

    const perPageField = useRef(null);

    function pageButtons(){
        let ans = [];
        let n = Math.ceil(files.length/perPage);
        if (n <= 10){
            for (let i=0; i<n; ++i){
                ans.push(
                    <Button key={i} onClick={()=>setPage(i)} active={i===page}>{i+1}</Button>
                );
            }
            return (
                <ButtonGroup className="me-2">
                    {ans}
                </ButtonGroup>
            )
        }
        ans.push(
            <Form.Control inline type="number" style={{marginRight: "10px", width:"90px"}} min={1} max={n} value={page+1} onChange={(e)=>{setPage(e.target.valueAsNumber-1)}}/>
        )
        ans.push(
            <Form.Range inline style={{width: "300px"}} min={1} max={n} step={1} value={page+1} onChange={(e)=>{setPage(e.target.valueAsNumber-1)}}/>
        )
        return (
            <InputGroup>
                {ans}
            </InputGroup>
        );
    }

    function chSort(name){
        if (name === "latlon" && sort.name === "latlon"){
            let newdir;
            if (sort.dir === -1) newdir = 1;
            if (sort.dir === 1) newdir = -2;
            if (sort.dir === -2) newdir = 2;
            if (sort.dir === 2) newdir = -1;
            return setSort({name, dir: newdir});
        }
        if (sort.name === name) setSort({name, dir: -sort.dir});
        else setSort({name, dir: -1});
    }

    function showArrow(name){
        if (sort.name === name) return (
            <div style={{display: "inline", fontStyle:"bold", fontSize:"1.5em", color: "blue"}}>
                {sort.dir < 0 ? " ↑" : " ↓"}
                {sort.dir === -2 ? "↑" : ""}
                {sort.dir === 2 ? "↓" : ""}
            </div>);
        return null;
    }

    function fileSort(a, b){
        function get(x){
            if (sort.name==="latlon"){
                if (sort.dir === 1 || sort.dir === -1) return x.metadata?.lat;
                return x.metadata?.lon;
            }
            if (sort.name==="modified") return new Date(x[sort.name]);
            return x[sort.name];
        }
        if (get(a) === undefined && get(b) === undefined) return 0;
        if (get(a) === undefined) return 1;
        if (get(b) === undefined) return -1;
        if (get(a) < get(b)) return -sort.dir;
        if (get(a) > get(b)) return sort.dir;
        return new Date(b.modified) - new Date(a.modified);
    }

    function filesOnPage(){
        return files.sort(fileSort).filter((f, i) => i>=page*perPage && i<(page+1)*perPage);
    }

    function isPageChecked(){
        return (filesOnPage().reduce((a, v)=> a && checked[v.fileid], true) && filesOnPage().length > 0);
    }

    function onPageCheck(event){
        let c = Object.assign({}, checked);
        filesOnPage().forEach(f => {
            c[f.fileid] = event.currentTarget.checked;
        })
        setChecked(c);
        return false;
    }

    return (
        <div>
            <ButtonToolbar className="justify-content-between">
                {pageButtons()}
                <InputGroup>
                    <FormControl type="number" defaultValue={perPage} ref={perPageField}/>
                    <Button variant="primary" onClick={()=>{setPerPage(perPageField.current.value); setPage(0)}}>Files per page</Button>
                </InputGroup>
            </ButtonToolbar>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th><Form.Check inline checked={isPageChecked()} onChange={onPageCheck}/>Select</th>
                        <th onClick={()=>chSort("filename")}>Filename{showArrow("filename")}</th>
                        <th onClick={()=>chSort("user")}>User{showArrow("user")}</th>
                        <th onClick={()=>chSort("sensor")}>Sensor{showArrow("sensor")}</th>
                        <th onClick={()=>chSort("modified")}>Last modified{showArrow("modified")}</th>
                        <th onClick={()=>chSort("size")}>Size{showArrow("size")}</th>
                        {
                            showMetadata ?
                            <th onClick={()=>chSort("latlon")}>Metadata{showArrow("latlon")}</th> :
                            null
                        }
                    </tr>
                </thead>
                <tbody>
                    {filesOnPage().map((f, i) => (
                        <tr key={i}>
                            <td><Form.Check checked={checked[f.fileid] ? true : false} onChange={event => {let c = Object.assign({}, checked); c[f.fileid]=event.currentTarget.checked; setChecked(c)}}/></td>
                            <td><Button variant="link" href={getFileLink(f)} target="_blank">{f.filename}</Button></td>
                            <td>{f.user}</td>
                            <td>{f.sensor}</td>
                            <td>{date_format(f.modified)}</td>
                            <td>{f.size}</td>
                            {
                                showMetadata ?
                                <td>
                                    {f.metadata?.lat ? `location: ${f.metadata.lat}, ${f.metadata.lon} ` : null}
                                    {f.metadata?.comment ? `comment: ${f.metadata.comment} ` : null}
                                    {f.metadata?.total_distance ? `Total distance: ${Math.round(f.metadata.total_distance)}m ` : null}
                                    {f.metadata?.desc ? `description: ${f.metadata.desc} ` : null},
                                    {f.metadata?.area ? `area (sq.km.): ${f.metadata.area} ` : null}
                                </td> :
                                null
                            }
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

function FolderView({files, checked, setChecked, expanded, setExpanded, lastgrid, setLastgrid, token, trial}){
    let unique = (v, i, a) => a.indexOf(v) === i;

    let userFolders = files.map(f => f.user).filter(unique);
    userFolders = userFolders.map(u => {
        return {
            name: u, 
            sensors: files.filter(f => f.user===u).map(f => f.sensor).filter(unique).map(s => {
                return {
                    name: s,
                    files: files.filter(f => f.user===u && f.sensor===s)
                }
            })
        }
    })

    function sensorChecked(userFolder, sensorFolder){
        return files.filter(f => f.user === userFolder.name && f.sensor === sensorFolder.name).reduce((a, v)=> a && checked[v.fileid], true);
    }
    function sensorUpdate(userFolder, sensorFolder, event){
        let c = Object.assign({}, checked);
        files.filter(f => f.user === userFolder.name && f.sensor === sensorFolder.name).forEach(f => {
            c[f.fileid] = event.currentTarget.checked;
        })
        setChecked(c);
    }
    function sensorExpanded(userFolder, sensorFolder){
        let name = `${userFolder.name}/${sensorFolder.name}`;
        return expanded[name] ? true : false;
    }
    function sensorChExp(userFolder, sensorFolder){
        let name = `${userFolder.name}/${sensorFolder.name}`;
        let vname = `${name}/view`;
        let e = Object.assign({}, expanded); 
        e[name]=!expanded[name];
        e[vname]=lastgrid;
        console.log(e);
        setExpanded(e);
    }
    function sensorView(userFolder, sensorFolder){
        let name = `${userFolder.name}/${sensorFolder.name}/view`;
        return expanded[name] ? true : false;
    }
    function sensorChView(userFolder, sensorFolder){
        let name = `${userFolder.name}/${sensorFolder.name}/view`;
        let e = Object.assign({}, expanded); 
        e[name]=!expanded[name];
        setLastgrid(e[name]);
        updateUserView(token.token, trial.id, undefined, e[name]);
        setExpanded(e);
    }
    
    function userChecked(userFolder){
        return files.filter(f => f.user === userFolder.name).reduce((a, v)=> a && checked[v.fileid], true);
    }
    function userUpdate(userFolder, event){
        let c = Object.assign({}, checked);
        files.filter(f => f.user === userFolder.name).forEach(f => {
            c[f.fileid] = event.currentTarget.checked;
        })
        setChecked(c);
    }
    function userExpanded(userFolder){
        return expanded[userFolder.name] ? true : false;
    }
    function userChExp(userFolder){
        let e = Object.assign({}, expanded); 
        e[userFolder.name]=!expanded[userFolder.name];
        setExpanded(e);
    }

    function getThumbLink(file, size){
        return `https://eapi.pcloud.com/getpubthumb?code=${file.pubcode}&size=${size?size:"32x32"}&crop=1`;
    }

    function list(files){
        files.sort((a, b) => a.filename < b.filename ? 1 : (a.filename > b.filename ? -1 : 0));
        return files.map((f, i) => {
            return (
                <div key={i}>
                    <Form.Check inline checked={checked[f.fileid] ? true : false} onChange={event => {let c = Object.assign({}, checked); c[f.fileid]=event.currentTarget.checked; setChecked(c)}}/>
                    <img src={getThumbLink(f)}/>
                    <Button variant="link" href={getFileLink(f)} target="_blank">{f.filename}</Button>
                    {
                        f.metadata?.lat ? 
                        <Button variant="link">
                            <img width="16" height="16" src={blue_marker}/>
                            {`${f.metadata.lat}, ${f.metadata.lon}`}
                        </Button> : 
                        null
                    }
                    {
                        f.metadata?.comment ?
                        <div className="d-inline">{`comment: ${f.metadata.comment}`}</div> :
                        null
                    }{
                        f.metadata?.total_distance ?
                        <div className="d-inline">{`Total distance: ${Math.round(f.metadata.total_distance)}m`}</div> :
                        null
                    }
                </div>
            )
        })
    }

    function grid(files){
        return (
            <Row style={{marginTop: "5px", marginBottom: "5px"}}>
                {files.map((f, i) => {
                    return (
                        <Col key={i} xs="auto" style={{padding:"1px"}}>
                            {/* <Card>
                                <Card.Img variant="top" src={getThumbLink(f, "128x128")}/>
                                <Card.Body>
                                    <Form.Check inline checked={checked[f.fileid] ? true : false} onChange={event => {let c = Object.assign({}, checked); c[f.fileid]=event.currentTarget.checked; setChecked(c)}}/>
                                    <Button variant="link" href={f.publink} target="_blank">{f.filename}</Button>
                                </Card.Body>
                            </Card> */}
                            <a href={f.publink} target="_blank">
                                <Image 
                                    // thumbnail
                                    // style={checked[f.fileid] ? {borderColor: "blue"} : {}}
                                    title={f.filename}
                                    src={getThumbLink(f, window.innerWidth > 1000 ? "196x196" : "128x128")} 
                                    
                                />
                            </a>
                            <Form.Check style={{position: "absolute", top: "0px", left: "5px"}} checked={checked[f.fileid] ? true : false} onChange={event => {event.stopPropagation(); let c = Object.assign({}, checked); c[f.fileid]=event.currentTarget.checked; setChecked(c)}}/>

                        </Col>
                    )
                })}
            </Row>
        )
    }

    return (
        <div>
            {
                userFolders.map((uf, i) => {
                    return (
                        <div key={i}>
                            <Form.Check inline checked={userChecked(uf)} onChange={event => userUpdate(uf, event)}/> 
                            <Button variant="outline-primary" onClick={()=>userChExp(uf)}>{userExpanded(uf) ? "v" : ">"}</Button>
                            <Button variant="link">{uf.name}</Button>
                            {
                                userExpanded(uf) ?
                                <div style={{paddingLeft: "2em"}}>{uf.sensors.map((sf, i) => {
                                    return (
                                        <div key={i}>
                                            {
                                                sensorExpanded(uf, sf) ?
                                                <Button variant="primary" style={{float: "right"}} onClick={()=>sensorChView(uf, sf)}>{`Switch to ${sensorView(uf, sf) ? "list" : "grid"} view`}</Button> :
                                                null
                                            }
                                            <Form.Check inline checked={sensorChecked(uf, sf)} onChange={event => sensorUpdate(uf, sf, event)}/> 
                                            <Button variant="outline-primary" onClick={()=>sensorChExp(uf, sf)}>{sensorExpanded(uf, sf) ? "v" : ">"}</Button>
                                            <Button variant="link">{sf.name}</Button>
                                            {
                                                sensorExpanded(uf, sf) ?
                                                <div style={{paddingLeft: "2em"}}>
                                                    {
                                                        sensorView(uf, sf) ?
                                                        grid(sf.files) :
                                                        list(sf.files)
                                                    }
                                                </div> :
                                                null
                                            }
                                        </div>
                                    )
                                })}</div> :
                                null
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

function Upload({upload}){
    return (
        <Alert variant="warning">
            {`${upload.filename}: ${upload.progress}`}
        </Alert>
    )
}

export default function Data({token, trial, setToken, setTab, setUnseenFiles}){
    const [files, setFiles] = useState([]);
    const stateRef = useRef()
    stateRef.current = files;
    const [processors, setProcessors] = useState([]);
    const [checked, setChecked] = useState({});
    const checkedRef = useRef()
    checkedRef.current = checked;
    const [alert, setAlert] = useState(false);
    const [view, setView] = useState(trial.view ? trial.view : "table");
    const [lastgrid, setLastgrid] = useState(trial.grid ? true : false);

    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState({name: "modified", dir: -1})

    const [airstations, setAirstations] = useState([]);
    const airsRef = useRef()
    airsRef.current = airstations;

    let [expanded, setExpanded] = useState({});

    const [pendingUploads, setPendingUploads] = useState([]);
    const fileToUpload = useRef()
    const sensorToUpload = useRef()

    function checkIfCloseTo(files, sensor, time, span){
        let file = files.filter(f => f.sensor === sensor).find(f => 
            Math.abs(new Date(f.modified) - new Date(time)) <= span 
        );
        if (file){
            let c = Object.assign({}, checkedRef.current); 
            c[file.fileid]=true; 
            setChecked(c)
        }
    }

    function reloadData(cb){
        getFiles(token.token, trial.id, data=>{
            if (data.err) return handleError(data.err, setToken);
            //console.log("recieved");
            let newFiles = [...stateRef.current];
            for (let f of data.files){
                let i = newFiles.findIndex(nf => nf.fileid === f.fileid);
                if (i === -1) newFiles.push(f);
                else newFiles[i] = f;
            }
            if (data.ids_to_keep) newFiles = newFiles.filter(f => data.ids_to_keep.includes(f.fileid))
            newFiles = newFiles.map(f => {
                if (f.overwritemodified){
                    f.modified = f.overwritemodified;
                }
                return f;
            })
            //console.log(newFiles.length);
            checkIfCloseTo(newFiles, "Air-pollution", new Date(), 10*60*1000)
            checkIfCloseTo(newFiles, "Air-pollution", new Date()-1*3600*1000, 3*60*1000)
            checkIfCloseTo(newFiles, "Air-pollution", new Date()-8*3600*1000, 3*60*1000)
            setFiles(newFiles);
            if (cb) cb()
            //setChecked({})
        })
    }

    useEffect(()=>{
        reloadData();
        getProcessors(token.token)
            .then(data => {
                if (data.err) return handleError(data.err, setToken);
                setProcessors(data.processors);
            });
        updateLastSeen(token.token, trial.id)
            .then(data => {
                if (data.err) return console.log("error updating seen files");
                setUnseenFiles(0);
            })
    }, [])

    function changeView(nview){
        updateUserView(token.token, trial.id, nview, undefined);
        setView(nview);
    }

    async function downloadFiles(){
        let fs = files.filter((f, i) => checked[f.fileid]).map(f => {return {fileid: f.fileid, filename: f.filename}});
        if (fs.length === 0) return alert("No files selected");
        let response = await download(token.token, fs);
        if (response.err) return handleError(response.err, setToken);
        let download_link = getZipLink(response.link)
        window.open(download_link);
    }

    function giveFeedback(){
        let fs = files.filter((f, i) => checked[f.fileid]).map(f => f.filename);
        if (fs.length === 0) return alert("No files selected");
        setTab({tab: "feedback", fileList: fs});
    }

    async function delFiles(){
        let fs = files.filter((f, i) => checked[f.fileid]).map(f => {return {fileid: f.fileid, filename: f.filename}});
        if (fs.length === 0) return alert("No files selected");
        if (!window.confirm("Are you sure you want to delete these data files? There will be no way to undo this operation!")) return;
        let response = await deleteFiles(token.token, trial.id, fs);
        if (response.err) return handleError(response.err, setToken);
        if (response.succ) reloadData();
    }

    function process(processor_name, option_name, settings_json){
        //TODO: Send metadata only if required (i.e. if processor.sensor is geolocated)
        let fs = files.filter((f, i) => checked[f.fileid]).map(f => {return {fileid: f.fileid, filename: f.filename, metadata: f.metadata, modified: f.modified}});
        processFiles(token.token, trial.id, fs, processor_name, option_name, settings_json, (data => {
            if (data.err) return handleError(data.err, setToken);
            window.alert(`Processing files (${fs.map(f => f.filename).join(", ")}) is now complete. You can see the processed files in Table or Tree view.`)
            reloadData();
        }))
    }

    function uploadFile(event){
        event.preventDefault();
        uploadToCloud(token.token, trial.id, sensorToUpload.current.value, fileToUpload.current.files[0], pendingUploads, setPendingUploads, (err, data) => {
                if (err) return handleError(err, setToken)
                if (data.err) return handleError(data.err, setToken);
                window.alert(`${fileToUpload.current.files[0].name} is successfully uploaded!`);
                reloadData();
            })
    }

    
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    const [processorMenu, setProcessorMenu] = useState(undefined);
    const menuRefs = useRef({});
    function showProcessorSettings(processor){
        setProcessorMenu(processor);
    }
    function closeProcessorSettings(){
        setProcessorMenu(undefined);
    }
    function submitProcessorSettings(e){
        e.preventDefault();
        let setting_values = {}
        for (let key in processorMenu.settings){
            setting_values[key] = menuRefs.current[key].value;
        }
        console.log(setting_values);
        //TODO: Somehow move this check to the processor code or have some seperate notion of processor verification
        // This will require a significant refactor to the communication between server and processor processes. We'll need to
        // roughly do:
        // 1. Send a request to process files from frontend
        // 2. Recieve the request in backand
        // 3. Download files (if we don't need just metadata)
        // 4. Request confirmation message from processor
        // 5. Send confirmation message to frontend
        // 6. Send ack/cancel to backend
        // 7. Continue or stop processing as usual
        let check = () => {
            if (processorMenu.output === "Sentinel-2" && processorMenu.sensor !== "geolocated"){
                let res = setting_values.resolution;
                let tracks = files.filter((f, i) => checked[f.fileid]).map(f => {return f.metadata.track});
                let cop_credits = 0;
                for (let track of tracks){
                    track = track.map(([latitude, longitude]) => {return {latitude, longitude}})
                    let bbox = [1000, 1000, -1000, -1000];
                    for (let {latitude, longitude} of track){
                        if (bbox[0] > longitude) bbox[0] = longitude;
                        if (bbox[1] > latitude) bbox[1] = latitude;
                        if (bbox[2] < longitude) bbox[2] = longitude;
                        if (bbox[3] < latitude) bbox[3] = latitude;
                    }
                    let x = getDistance({lat: bbox[1], lon: bbox[0]}, {lat: bbox[3], lon: bbox[0]})/res;
                    let y = getDistance({lat: bbox[1], lon: bbox[0]}, {lat: bbox[1], lon: bbox[2]})/res;
                    cop_credits += Math.ceil(x/512) * Math.ceil(y/512);
                }
                return window.confirm(`Fetching these satellite images will use approximately ${cop_credits} copernicus credits. Would you like to proceed?`)
            }
            if (processorMenu.output === "Sentinel-2" && processorMenu.sensor === "geolocated"){
                // This is for a single region of all selected files
                let res = setting_values.resolution;
                let all_points = files.filter((f, i) => checked[f.fileid]).map(f => {
                    if (f.metadata.track) {
                        return f.metadata.track.map(([latitude, longitude]) => { return { latitude, longitude } })
                    }
                    if (f.metadata.bbox) {
                        let bbox = f.metadata.bbox
                        return [
                            { latitude: bbox[1], longitude: bbox[0] },
                            { latitude: bbox[3], longitude: bbox[2] }
                        ]
                    }
                    return [{ latitude: f.metadata.lat, longitude: f.metadata.lon }]
                })
                all_points = [].concat(...all_points);
                let bbox = [1000, 1000, -1000, -1000];
                for (let { latitude, longitude } of all_points) {
                    if (bbox[0] > longitude) bbox[0] = longitude;
                    if (bbox[1] > latitude) bbox[1] = latitude;
                    if (bbox[2] < longitude) bbox[2] = longitude;
                    if (bbox[3] < latitude) bbox[3] = latitude;
                }
                console.log("computed bounding box for all files:", bbox);

                let x = (getDistance({ lat: bbox[1], lon: bbox[0] }, { lat: bbox[3], lon: bbox[0] }) + 2*setting_values.padding) / res;
                let y = (getDistance({ lat: bbox[1], lon: bbox[0] }, { lat: bbox[1], lon: bbox[2] }) + 2*setting_values.padding) / res;
                let cop_credits = Math.ceil(x / 512) * Math.ceil(y / 512);
                return window.confirm(`Fetching this satellite image will use approximately ${cop_credits} copernicus credits. Would you like to proceed?`)
            }
            return true;
        }
        if (check()){
            process(processorMenu.name, undefined, JSON.stringify(setting_values));
        }
        closeProcessorSettings();
        return false;
    }

    return (
        <div>
            {
                processorMenu?.settings ?
                <Modal show={processorMenu?.settings ? true : false} onHide={closeProcessorSettings}>
                    <Modal.Header closeButton>
                        <Modal.Title>{processorMenu.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={submitProcessorSettings}>
                            {
                                Object.entries(processorMenu.settings).map(entry => {
                                    let [key, value] = entry;
                                    let defaultValue = value.default;
                                    //TODO: Move defaults to processors.json file in some reasonable format.
                                    if (processorMenu.sensor === "geolocated" && (key === "start_date" || key === "end_date")){
                                        
                                        //TODO: Do this maybe expensive computation only once and not for every setting key
                                        let fs = files.filter((f, i) => checked[f.fileid]).map(f => { return { fileid: f.fileid, filename: f.filename, metadata: f.metadata, modified: f.modified } });
                                        let times = fs.map(f => {
                                            let by_space = f.filename.split(' ');
                                            let date_from_name = new Date(by_space[by_space.length - 2]);
                                            if (date_from_name instanceof Date && !isNaN(date_from_name)){
                                                return date_from_name
                                            }
                                            return new Date(f.modified)
                                        })
                                        let start_time = new Date(Math.min.apply(null, times))
                                        start_time.setDate(start_time.getDate())
                                        let end_time = new Date(Math.max.apply(null, times))
                                        end_time.setDate(end_time.getDate())
                                        console.log("Calculated times", start_time, end_time)
                                        let start_date = formatDate(start_time)
                                        let end_date = formatDate(end_time)
                                        console.log("Calculated dates: ", start_date, end_date)

                                        if (key == "start_date"){
                                            defaultValue = start_date
                                        }
                                        if (key == "end_date"){
                                            defaultValue = end_date
                                        }
                                    }
                                    else {
                                        if (key == "start_date") {
                                            let oneMonthAgo = new Date();
                                            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
                                            defaultValue = formatDate(oneMonthAgo);
                                        }
                                        if (key == "end_date") {
                                            let today = new Date();
                                            defaultValue = formatDate(today);
                                        }
                                    }
                                    return (
                                        <Form.Group>
                                            <Form.Label>{value.name}</Form.Label>
                                            {
                                                value.type === "select" ?
                                                <Form.Select defaultValue={value.options[0]} ref={element => menuRefs.current[key] = element}>
                                                    {value.options.map(option => {
                                                        return (
                                                            <option>{option}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                :
                                                <Form.Control type={value.type} defaultValue={defaultValue} ref={element => menuRefs.current[key] = element}/>
                                            }
                                        </Form.Group>
                                    )
                                })
                            }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeProcessorSettings}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={submitProcessorSettings}>
                            Fetch
                        </Button>
                    </Modal.Footer>
                </Modal> :
                null
            }
            {
                alert ?
                <Alert variant="info">Your files are being prepared for download...</Alert> :
                null
            }
            <Button variant="primary" style={{margin:"5px", float:"right"}} onClick={()=>{setChecked({})}}>Unselect all</Button>
            <Button variant="primary" style={{margin:"5px", float:"right"}} onClick={()=>{
                let ans = {};
                files.forEach(f => ans[f.fileid] = true);
                setChecked(ans);
            }}>Select all</Button>
            {
                view !=="table" ?
                <Button variant="primary" style={{margin:"5px", float:"right"}} onClick={()=>changeView("table")}>
                    Table view
                </Button> : 
                null
            }{
                view !=="folders" ?
                <Button variant="primary" style={{margin:"5px", float:"right"}} onClick={()=>changeView("folders")}>
                    Tree view
                </Button> : 
                null
            }{
                view !=="map" ?
                <Button variant="primary" style={{margin:"5px", float:"right"}} onClick={()=>changeView("map")}>
                    Map view
                </Button> : 
                null
            }
            <h3 style={{marginBottom: "20px"}}>Files</h3>
            {
                view === "table" ?
                <TableView files={files} checked={checked} setChecked={setChecked} perPage={perPage} setPerPage={setPerPage} page={page} setPage={setPage} sort={sort} setSort={setSort}/> :
                null
            }{
                view === "folders" ?
                <FolderView files={files} checked={checked} setChecked={setChecked} expanded={expanded} setExpanded={setExpanded} lastgrid={lastgrid} setLastgrid={setLastgrid} token={token} trial={trial}/> :
                null
            }{
                view === "map" ?
                <MapView token={token} setToken={setToken} reload={reloadData} trial_id={trial.id} trial={trial} files={files} checked={checked} setChecked={setChecked}/> :
                null
            }
            <Button style={{margin:"5px"}}variant="primary" onClick={downloadFiles}>Download selected</Button>
            {
                trial.isGuest ?
                null :
                <Button style={{margin:"5px"}}variant="primary" onClick={giveFeedback}>Feedback about selected</Button>
            }{
                trial.isGuest ?
                null :
                <Button style={{margin:"5px"}}variant="primary" onClick={delFiles}>Delete selected</Button>
            }
            {
                trial.isGuest || trial.locked ?
                null :
                processors.filter(p => {
                    if (p.available !== "all" && !p.available.includes(trial.name)) return false;
                    let selected = files.filter((f, i) => checked[f.fileid]);
                    if (selected.length==0) return false;
                    if (p.min && selected.length < p.min) return false;
                    if (p.max && selected.length > p.max) return false;
                    if (p.additional){
                        let bonus = selected.filter(f => p.additional.includes(f.sensor));
                        if (bonus.length > 0) return false;
                        selected = selected.filter(f => p.additional.includes(f.sensor));
                        if (selected.length == 0) return false;
                    }
                    if (p.sensor == "geolocated"){
                        return selected.reduce((acc, f) => (acc && f.metadata?.lat), true)
                    }
                    return selected.reduce((acc, f) => (acc && p.sensor.includes(f.sensor)), true)
                }).map((p, i) => (
                    p.options ?
                    <DropdownButton as={ButtonGroup} style={{margin:"5px"}} title={p.name}>
                        {
                            p.options.map((o, i) => (
                                <Dropdown.Item key={i} onClick={()=>{process(p.name, o.name)}}>{o.name}</Dropdown.Item>
                            ))
                        }
                    </DropdownButton>:
                    (
                        p.settings ?
                        <Button key={i} style={{margin:"5px"}} variant="primary" onClick={()=>{showProcessorSettings(p)}}>{p.name}</Button> :
                        <Button key={i} style={{margin:"5px"}} variant="primary" onClick={()=>{process(p.name)}}>{p.name}</Button>
                    )
                ))
            }
            {
                trial.isGuest ?
                null :
                <Jumbotron>
                    <h3>Upload file</h3>
                    <Form onSubmit={uploadFile}>
                        <Form.Control type="file" style={{marginBottom: "10px"}} ref={fileToUpload}/>
                        <Form.Select ref={sensorToUpload}>
                            {trial.sensors.map((sensor, i) => {
                                return (
                                    <option key={i}>{sensor}</option>
                                )
                            })}
                        </Form.Select>
                        <Button variant="primary" type="submit">Upload</Button>
                    </Form>
                    {
                        pendingUploads.map((upload, i) => (
                            <Upload upload={upload} key={i}/>
                        ))
                    }
                </Jumbotron>
            }
        </div>
    );
}
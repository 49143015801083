import React from 'react';
import movesense1 from './images/movesense1.jpg'
import movesense2 from './images/movesense-app1.jpg'
import { Col, Row } from 'react-bootstrap';

export default function Movesense(){
    return (
        <div>
            <h3 style={{marginTop:"20px"}}>LLcloud+Movesense Joint Offers to Develop and Validate Novel Wearables</h3>
            <p><b><a href="https://www.movesense.com" target="_blank">Movesense</a> is global leader in developing and manufacturing cutting edge wireless wearable sensors for sports, medical, patient monitoring and research applications – and beyond.</b></p>
            <p>Movesense is an ISO 13485:2016 certified, privately owned high technology company and their sensors are designed and manufactured in Finland. The company serves their global customer base with high quality Movesense branded as well as OEM white labelled sensor products for medical, sports and variety of other uses requiring high quality ECG, HR, HRV and/or motion measurements. Their diverse customer base includes sports brands, medical device manufacturers, researchers, IoT innovators and many other industries, and they supply both Class IIa medically certified Movesense MD sensors and Movesense sports sensors.</p>
            <Row className="justify-content-md-center" style={{margin:"60px"}}>
                <Col xs lg={6} style={{textAlign:"right"}}>
                    <img style={{height:"300px"}} src={movesense1}></img>
                </Col>
                <Col xs lg={6} style={{textAlign: "left"}}>
                    <img style={{height:"300px"}} src={movesense2}></img>
                </Col>
            </Row>
            <p><b>Movesense customers can now use LLcloud as a smart cloud backend to store, visualise and analyse Movesense sensor data and combine it with other sensor platforms to build and validate new wearable devices and Movesense applications.</b> For example, in sports wearables and apps, Movesense data could be combined with GPS tracks recorded with sports watches, action camera videos, food logging via food photo recognition, or other sensor data from smartphones and smartwatches.</p>
            <p>LLcloud is ideal for designing, developing, and testing wearables. It is designed and implemented specifically for multi-sensor data gathering, analysis and visualisation in the cloud. It is one of the few platforms in the world that allows the joint analysis of data from wearable sensors like Movesense and fixed IoT sensor/network data. LLcloud can be used also for Living Lab trials with test users, who can receive instructions and provide feedback via the same platform.</p>
            <p>LLcloud features for wearable applications include advanced geofences, zones, and tracking, various mathematical metrics to compare location tracks when developing or validating the performance of novel wearable trackers, and communication with various wearable sensors and devices via WiFi, 5G and LoRaWAN (BLE for Movesense sensors).</p>
            <p style={{marginTop:"60px"}}><b>The LLcloud+Movesense Joint Offers to Develop and Validate Novel Wearables include all the software and hardware features and infrastructure you get with an LLcloud subscription plan together with several <a href="https://www.movesense.com/product/movesense-developer-kit-2-0/" target="_blank">Movesense Developer Kits 2.0</a> included in this price!</b></p>
            <p><b>If you are a company which wants to design and develop a new wearable device or/and app</b>, you can choose the <b>Movesense develop offer (includes 3/5 Movesense Developer Kits 2.0 with a Standard LLcloud plan for 6/12 months, respectively)</b> and make use of the reliable and flexible LLcloud hardware and software cloud infrastructure, and the seamless integration of the Movesense sensor with LLcloud. For inspiration you can <a href="https://www.movesense.com/showcase/" target="_blank">see how companies have used in the past the Movesense platform to build great consumer products</a> and also check some of the <a href="https://movesense.com/publications" target="_blank">Movesense related publications and research papers</a>.</p>
            <p><b>If you are a company that has already developed a new wearable device or/and app using Movesense</b>, you can choose the <b>Movesense test/validate offer (includes 5/10 Movesense Developer Kits 2.0 with a Premium LLcloud plan for 6/12 months, respectively)</b>, design and perform multi-sensor Living Lab trials with test users and combine the Movesense data gathered with data coming from other platforms integrated with LLcloud, such as <a href="https://www.strava.com/" taget="_blank">Strava</a> (for routes and location data), <a href="https://phyphox.org/" taget="_blank">Phyphox</a> (for smarphone sensor data), <a href="https://www.apple.com/ios/health/" taget="_blank">Apple Health</a> (for Apple Watch and other data), <a href="https://gopro.com/" taget="_blank">GoPro</a> (for GoPro videos and telemetry data), etc. </p>
            <p style={{marginTop:"60px"}}><b>To start using Movesense with LLcloud, please choose and request an <a href="/pricing/wearables">LLcloud subscription plan</a></b>, selecting a <b>Movesense develop offer</b> or a <b>Movesense test/validate offer</b>, and describe in the request you would like to use it with Movesense sensors. Also consult <a href="/publicapi">LLcloud®’s public API</a> to see how your novel wearable or app can send data to LLcloud which can be combined with data from other sensors and platforms.</p>
            <p><b>If you are an iPhone user and already have a Movesense sensor, you can download the LLcloud Movesense data importer app for iOS (coming soon) and test the integration of your sensor and the data it records with LLcloud.</b></p>
            <p><i>Once your LLcloud+Movesense plan has been activated and paid for (following also a 1 month free trial of LLcloud, if requested), the Movesense Developer Kit 2.0s included in this joint offer will be sent directly by Movesense from Finland to the postal address you have provided.</i></p>
        </div>
    )
}
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Jumbotron from './jumbotron'
import handleError from './handleError';
import './file-upload.css';

async function getInstructionsList(token, trial_id){
    return fetch("/api/instructionslist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id})
    })
        .then(data => data.json());
}

async function deleteInstruction(token, trial_id, instruction_id){
    return fetch("/api/deleteinstruction", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id, instruction_id})
    })
        .then(data => data.json());
}

async function addInstruction(token, trial_id, instruction){
    const formData = new FormData();
    formData.append('token', token);
    formData.append('trial_id', trial_id);
    formData.append('instruction', instruction);
    return fetch("/api/createinstruction", {
        method: "POST",
        body: formData
    })
        .then(data => data.json());
}

async function updateInstruction(token, trial_id, instruction_id, instruction){
    const formData = new FormData();
    formData.append('token', token);
    formData.append('trial_id', trial_id);
    formData.append('instruction_id', instruction_id);
    formData.append('instruction', instruction);
    return fetch("/api/updateinstruction", {
        method: "POST",
        body: formData
    })
        .then(data => data.json());
}

async function addComment(token, trial_id, comment){
    return fetch("/api/createicomment", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id, comment})
    })
        .then(data => data.json());
}

function UpdateForm( {token, trial_id, instruction_id, setToken, instructions, setInstructions} ){
    const file = useRef(null);

    function handleSubmit(e){
        if (!window.confirm(`Are you sure you want to update the current instruction file with file '${file.current.files[0].name}'`)) return
        updateInstruction(token, trial_id, instruction_id, file.current.files[0])
            .then(data => {
                if (data.err) return handleError(data.err, setToken);
                setInstructions(instructions.map(i => i._id === instruction_id ? data.instruction : i))
                alert("Instruction successfully updated!");
            })
    }

    return (
        <Form.Control className="custom-file-update" onChange={handleSubmit} type="file" ref={file}/>
    );
}

export default function Instructions( {trial, token, setToken} ){
    const [instructions, setInstructions] = useState([]);
    const file = useRef(null);
    const [commentForm, setCommentForm] = useState(undefined);
    const commentBody = useRef(null);

    useEffect(()=>{
        getInstructionsList(token.token, trial.id)
            .then(data => {
                if (data.err) return handleError(data.err, setToken);
                setInstructions(data.instructions);
            })
    }, [])

    function createInstruction(event){
        event.preventDefault();
        addInstruction(token.token, trial.id, file.current.files[0])
            .then(data => {
                if (data.err) return handleError(data.err, setToken);
                setInstructions(instructions.concat([data.instruction]));
            })
    }

    function removeInstruction(instruction_id){
        deleteInstruction(token.token, trial.id, instruction_id)
            .then(data => {
                if (data.err) return handleError(data.err, setToken);
                if (data.success){
                    setInstructions(instructions.filter(x => x._id !== instruction_id));
                }
            })
    }

    function createComment(event){
        event.preventDefault();
        let comment = {
            body: commentBody.current.value,
            reply_id: commentForm
        }
        addComment(token.token, trial.id, comment)
            .then(data => {
                if (data.err) return handleError(data.err, setToken);
                setInstructions(instructions.concat([data.instruction]));
            })
        setCommentForm(undefined);
    }

    return (
        <div>
            <h3>Instructions</h3>
            {instructions.filter((ins)=>!ins.reply_id).map((ins, i) => (
                <Jumbotron key={i}>
                    <Row>
                        <Col>
                            <Button variant="link" target="_blank" href={`/instructions/${ins.filename}`}>{ins.originalname}</Button>
                        </Col>
                        {
                            trial.isAdmin ?
                            <Col>
                                <UpdateForm token={token.token} setToken={setToken} instructions={instructions} setInstructions={setInstructions} instruction_id={ins._id} trial_id={trial.id}/>
                            </Col> :
                            null
                        }
                        {
                            trial.isAdmin ?
                            <Col>
                                <Button variant="primary" onClick={()=>{removeInstruction(ins._id)}}>Delete</Button>
                            </Col> :
                            null
                        }
                    </Row>
                    {
                        ins.author ?
                        <Row><Col>{`Uploaded by ${ins.author} on ${ins.date}`}</Col></Row> : 
                        null
                    }
                    <div style={{marginLeft: "20px"}}>
                        {instructions.filter(comm => comm.reply_id === ins._id).map((comm, i) => (
                            <Row style={{margin:"10px"}} key={i}>
                                <Form.Control as="textarea" value={comm.body} rows={comm.body.split(/\r\n|\r|\n/).length} readOnly/>
                            </Row>
                        ))}
                        {
                            trial.isAdmin ?
                            (
                                commentForm === ins._id ?
                                <Form onSubmit={createComment}>
                                    <Form.Control as="textarea" rows={5} ref={commentBody}/>
                                    <Button variant="primary" style={{float: "right"}} onClick={()=>{setCommentForm(undefined)}}>Back</Button>
                                    <Button variant="primary" type="submit">Add comment</Button>
                                </Form> :
                                <Button variant="primary" onClick={()=>{setCommentForm(ins._id)}}>New comment</Button>
                            ) :
                            null
                        }
                    </div>
                </Jumbotron>
            ))}
            {
                trial.isAdmin ?
                <Jumbotron>
                    <h3>New instruction</h3>
                    <Form onSubmit={createInstruction}>
                        <Form.Control type="file" style={{marginBottom: "10px"}} ref={file}/>
                        <Button variant="primary" type="submit">Add instruction</Button>
                    </Form>
                </Jumbotron> :
                null
            }
        </div>
    )
}
import React from 'react';
import { Jumbotron, Button } from 'react-bootstrap';

export default function DataProtection(){
    return (
        <div>
            <h3 style={{marginTop:"1em"}}>LLcloud Personal Data Protection Documents:</h3>
            <Button style={{display:"block", textAlign:"left"}} variant="link" target="_blank" href="documents/LLcloud_Data_Protection_Policy_Basic_Framework_EN.pdf">
                Data Protection Policy Basic Framework (in English)
            </Button>
            <Button style={{display:"block", textAlign:"left"}} variant="link" target="_blank" href="documents/LLcloud_Data_Protection_Policy_Basic_Framework_BG.pdf">
                Data Protection Policy Basic Framework (in Bulgarian)
            </Button>
            {/* <Button style={{display:"block", textAlign:"left"}} variant="link" target="_blank" href="documents/Exercising Rights and Requests Prossesing Policy.pdf">
                Exercising Rights and Requests Prossesing Policy (in Bulgarian)
            </Button>
            <Button style={{display:"block", textAlign:"left"}} variant="link" target="_blank" href="documents/Personal Data Protection and Extermination Policy.pdf">
                Personal Data Protection and Extermination Policy (in Bulgarian)
            </Button>
            <Button style={{display:"block", textAlign:"left"}} variant="link" target="_blank" href="documents/Personal Data Breach Policy.pdf">
                Personal Data Breach Policy (in Bulgarian)
            </Button>
            <Button style={{display:"block", textAlign:"left"}} variant="link" target="_blank" href="documents/Video Surveillance Policy.pdf">
                Video Surveillance Policy (in Bulgarian)
            </Button> */}
        </div>
    )
}
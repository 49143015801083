import React from 'react';
import {BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from './dashboard';
import Trial from './trial';
import Register from './register';
import Reset from './reset';
import Login from './login';
import useToken from './useToken';
import GetToken from './getToken';
import Home from './home'
import Gallery from './gallery'
import SmartCities from './smartcities'
import About from './about'
import AboutLL from './about_ll'
import Pricing from './pricing'
import Nav from 'react-bootstrap/Nav';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import Settings from './settings';
import WebTitle from './images/WebTitle.svg'
import DataProtection from './dataprotection';
import Subscriptions from './subscriptions'
import Api from './publicApi'
import Wearables from './wearables';
import Company from './company';
import Contact from './contact';
import Iot from './iot';
import LivingLabTrials from './livin_lab_trials';
import Movesense from './movesense';
import EarthObservation from './earthObservation';
import PortAnalytics from './port-analytics';
import OilandGasWellsMonitoring from './oil-and-gas-wells-monitoring';

function App() {
  const {token, setToken} = useToken();

  // used to differentiate between llcloud.eu and livinglab.cloud
  let isLivingLab = (window.location.hostname === "livinglab.cloud" || window.location.hostname === "localhost?");

  return (
    <BrowserRouter>
    <div>
      <Navbar bg="light" expand="lg">
        <div className="container">
          {
            isLivingLab ?
            null :
            <Navbar.Brand><img style={{height: "1em"}} src={WebTitle}/></Navbar.Brand>
          }
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {
                !token ?
                <Nav.Item>
                  <Nav.Link href="/">Home</Nav.Link>
                </Nav.Item> :
                null
              }
              {
                token ?
                <Nav.Item><Nav.Link href="/dashboard">Dashboard</Nav.Link></Nav.Item> :
                null
              }
              {
                token && token.userInfo.superuser ?
                <Nav.Item><Nav.Link href="/subscriptions">Subscriptions</Nav.Link></Nav.Item> :
                null
              }
              {/* <Nav.Item>
                <Nav.Link href="/pricing">Pricing</Nav.Link>
              </Nav.Item>
              {
                !token ?
                <Nav.Item>
                  <Nav.Link href="/usecases">Use cases</Nav.Link>
                </Nav.Item>:
                null
              } */}
              {
                !token && !isLivingLab ?
                <NavDropdown title="Solutions">
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/solutions/port-analytics">Multi-Sensor Port Analytics</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/solutions/oil-and-gas-wells-monitoring">Multi-Sensor Orphaned Oil & Gas Wells Monitoring</NavDropdown.Item>
                </NavDropdown> :
                null
              }
              {
                !token ?
                <Nav.Item>
                  <Nav.Link href="/about">About</Nav.Link>
                </Nav.Item>:
                null
              }
              {
                !token && !isLivingLab ?
                <NavDropdown title="Earth Observation">
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/earthobservation">LLcloud for Earth observation</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} as={NavHashLink} to="/earthobservation#usecases">Use cases</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/pricing/earthobservation">Pricing</NavDropdown.Item>
                </NavDropdown> :
                null
              }
              {
                !token && !isLivingLab ?
                <NavDropdown title="IoT">
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/iot">LLcloud for IoT</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/iot#usecases">Use cases</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/pricing/iot">Pricing</NavDropdown.Item>
                </NavDropdown> :
                null
              }
              {
                !token && !isLivingLab ?
                <Nav.Item>
                  <Nav.Link href="/smartcities">Smart Cities</Nav.Link>
                </Nav.Item> :
                null
              }
              {
                !token && !isLivingLab ?
                <NavDropdown title="Wearables">
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/wearables">LLcloud for Wearables</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} as={NavHashLink} to="/wearables#usecases">Use cases</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/integrated-platforms/movesense">LLcloud+Movesense</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/pricing/wearables">Pricing</NavDropdown.Item>
                </NavDropdown> :
                null
              }
              {
                !token && isLivingLab ?
                <NavDropdown title="Living Lab trials">
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/livinglabtrials">Living Lab trials</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} as={NavHashLink} to="/livinglabtrials#usecases">Use cases</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} href="/pricing/livinglabs">Pricing</NavDropdown.Item>
                </NavDropdown>:
                null
              }
              {
                !token ?
                <NavDropdown title="Developers">
                  <NavDropdown.Item href="/publicapi">Public API</NavDropdown.Item>
                </NavDropdown> :
                null
              }
              {
                !token ?
                ( isLivingLab ? 
                  <Nav.Item>
                    <Nav.Link href="/pricing/livinglabs">Pricing</Nav.Link>
                  </Nav.Item>:
                  <NavDropdown title="Pricing">
                    <NavDropdown.Item href="/pricing/earthobservation">Pricing for Earth Observation</NavDropdown.Item>
                    <NavDropdown.Item href="/pricing/iot">Pricing for IoT</NavDropdown.Item>
                    <NavDropdown.Item href="/pricing/wearables">Pricing for Wearables</NavDropdown.Item>
                  </NavDropdown> 
                ):
                null
              }
              {/* {
                !token ?
                <Nav.Item>
                  <Nav.Link href="/dataprotection">Personal data protection</Nav.Link>
                </Nav.Item>:
                null
              } */}
            </Nav>
            {
              !token ?
              <Nav>
                <Nav.Item>
                  <Nav.Link href="https://news.llcloud.eu">News</Nav.Link>
                </Nav.Item>
                <NavDropdown title="Company">
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}} as={NavHashLink} to="/company">Mission</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}}  as={NavHashLink} to="/company#team">Team</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}}  as={NavHashLink} to="/company#advisoryboard">Advisory board</NavDropdown.Item>
                  <NavDropdown.Item className="nav-item" style={{backgroundColor:"white", color:"black"}}  as={NavHashLink} to="/company#partners">Partners</NavDropdown.Item>
                </NavDropdown>
                <Nav.Item>
                  <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/signin">Sign in</Nav.Link> 
                </Nav.Item>
              </Nav> :
              <Nav>
                <Nav.Link href="/settings">User settings</Nav.Link>
                <Nav.Link href={window.location.host === "digitalspaces.livinglab.cloud" ? "https://llcloud.eu/" : "/"} onClick={() => {
                  setToken(null)
                }}>Sign out</Nav.Link>
              </Nav>
            }
          </Navbar.Collapse>
        </div>
      </Navbar>
          <Routes>
            <Route path="signin" element={
              <div className="container">
                <Login setToken={setToken} onLogin={(navigate)=>{
                  navigate("/dashboard");
                }} isLivingLab={isLivingLab}/>
              </div>
            }/>
            <Route path="dashboard" element={
              <div className="container">
                {
                  !token ?
                  <Login setToken={setToken} isLivingLab={isLivingLab}/> : 
                  <Dashboard token={token} setToken={setToken} />
                }
              </div>
            }/>
            <Route path="trial/:name" element={
              <div className='container'>
                {
                  !token ?
                  <Login setToken={setToken} isLivingLab={isLivingLab}/> : 
                  <Trial token={token} setToken={setToken}/>
                }
              </div>
            }/>
            <Route path="trial/:name/:token" element={
              <div className='container'>
                <GetToken setToken={setToken}/>
              </div>
            }/>
            <Route path="subscriptions" element={
              <div className="container">
                {
                  !token || !token.userInfo.superuser ?
                  <Login setToken={setToken} isLivingLab={isLivingLab}/> : 
                  <Subscriptions token={token} setToken={setToken} />
                }
              </div>
            }/>
            <Route path="settings" element={
              <div className="container">
                {
                  !token ?
                  <Login setToken={setToken} isLivingLab={isLivingLab}/> :
                  <Settings token={token} setToken={setToken}/>
                }
              </div>
            }/>
            <Route path="register" element={
              <div className="container">
                <Register setToken={setToken}/>
              </div>
            }/>
            <Route path="reset" element={
              <div className="container">
                <Reset setToken={setToken}/>
              </div>
            }/>
            {/* <Route path="/registerAdmin">
              <div className="container">
                <RegisterAdmin/>
              </div>
            </Route> */}
            <Route path="dataprotection" element={
              <div className="container">
                <DataProtection/>
              </div>
            }/>
            <Route path="solutions/oil-and-gas-wells-monitoring" element={
              <div className="container">
                <OilandGasWellsMonitoring/>
              </div>
            }/>
            <Route path="solutions/port-analytics" element={
              <div className="container">
                <PortAnalytics/>
              </div>
            }/>
            <Route path="pricing/:sector" element={
              <div className="container">
                <Pricing/>
              </div>
            }/>
            <Route path="about" element={
              <div className="container">
                {
                  isLivingLab ?
                  <AboutLL/> :
                  <About/>
                }
              </div>
            }/>
            <Route path="usecases" element={
              <Gallery/>
            }/>
            <Route path="iot" element={
              <div className="container">
                <Iot/>
              </div>
            }/>
            <Route path="wearables" element={
              <div className="container">
                <Wearables/>
              </div>
            }/>
            <Route path="smartcities" element={
              <div className="container">
                <SmartCities/>
              </div>
            }/>
            <Route path="earthobservation" element={
              <div className="container">
                <EarthObservation/>
              </div>
            }/>
            <Route path="livinglabtrials" element={
              <div className="container">
                <LivingLabTrials/>
              </div>
            }/>
            <Route path="company" element={
              <div className="container">
                <Company isLivingLab={isLivingLab}/>
              </div>
            }/>
            <Route path="contact" element={
              <div className="container">
                <Contact/>
              </div>
            }/>
            <Route path="publicapi" element={
              <Api/>
            }/>
            <Route path="integrated-platforms/movesense" element={
              <div className="container">
                <Movesense/>
              </div>
            }/>
            <Route path="/" element={
              <Home setToken={setToken} isLivingLab={isLivingLab}/>
            }/>
          </Routes>
    </div>
        </BrowserRouter>
  );
}

export default App;

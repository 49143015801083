import React, { useRef, useState } from 'react';
import { Button, Form, Table, Offcanvas, Dropdown, DropdownButton, OverlayTrigger, Popover, PopoverHeader } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

async function subscription(data){
    console.log(data);
    return fetch("/api/requestsubscription", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(data => data.json());
}

export default function Pricing(){
    let { sector } = useParams();
    const [showForm, setShowForm] = useState(false);
    const [plan, setPlan] = useState("");
    const [stIntegrated, setStIntegrated] = useState({})
    const [standardShow, setStandardShow] = useState(false);
    const [prIntegrated, setPrIntegrated] = useState({})
    const [premiumShow, setPremiumShow] = useState(false);
    const [duration, setDuration] = useState("12m");
    const emailEl = useRef(null);
    const nameEl = useRef(null);
    const surnameEl = useRef(null);
    const companyEl = useRef(null);
    const usecaseEl = useRef(null);
    const sensorsEl = useRef(null);
    const planEl = useRef(null);
    const durationEl = useRef(null);
    const freeTrialEl = useRef(null);

    function openForm(type){
        setShowForm(true);
        setPlan(type);
    }

    function how_many_movesense(){
        if (plan === "standard"){
            if (duration == "6m") return 3;
            return 5;
        }
        if (duration == "6m") return 5;
        return 10;
    }

    function integrated_string_from_plan(integrated, overwrite){
        let integrated_list = [];
        for (let platform in integrated){
            if (integrated[platform]){
                if (overwrite){
                    if (platform == "Movesense develop (for novel wearables)"){
                        platform = `Movesense develop offer (includes ${how_many_movesense()} Movesense Developer Kits 2.0)`
                    }
                    if (platform == "Movesense validate (for novel wearables)"){
                        platform = `Movesense validate offer (includes ${how_many_movesense()} Movesense Developer Kits 2.0)`
                    }
                }
                integrated_list.push(platform)
            }
        }
        return integrated_list.join(", ");
    }
    function integrated_string(){
        return plan === "premium" ? integrated_string_from_plan(prIntegrated, true) : integrated_string_from_plan(stIntegrated, true);
    }

    async function handleSubmit(e){
        e.preventDefault();

        let subscription_data ={
            name: nameEl.current.value,
            surname: surnameEl.current.value,
            email: emailEl.current.value,
            company: companyEl.current.value,
            usecase: usecaseEl.current.value,
            sensors: sensorsEl.current.value,
            plan: planEl.current.value,
            duration: durationEl.current.value,
            integrated: integrated_string(),
            free_trial: freeTrialEl.current.value
        }
        let result = await subscription(subscription_data);
        if (result.err){
            alert("Something went wrong, please try again!");
        }else{
            alert("Request received, we will contact you in a few days");
            setShowForm(false);
        }
    }

    function to_sector_name(sector){
        return sector === "wearables" ? "Wearables" : (sector === "iot" ? "IoT" : "Earth Observaton")
    }

    function integrated_platforms(){
        return sector === "wearables" ?
            [
                "Phyphox (for smarphone sensor data)",
                "Apple Health (for Apple Watch data)",
                "GoPro (for GoPro videos and telemetry data)",
                "Strava (for routes and location data)",
                "Movesense develop (for novel wearables)",
                "Movesense validate (for novel wearables)",
                "None"
            ] :
            sector === "iot" ?
                [
                    "Strava (for routes and location data)",
                    "GoPro (GoPro videos and telemetry data)",
                    "Drones (videos and telemetry data)",
                    "Phyphox (for smarphone sensor data)",
                    "Apple Health (for Apple Watch data)",
                    "None"
                ] :
                [
                    "Copernicus Sentinel images (for EO from space)",
                    "Drone image, video and telemetry (for EO from the air)",
                    "GoPro video and telemetry (for EO from the ground)",
                    "Strava (for routes and location data)",
                    "Human annotation of EO data",
                    "None"
                ]
    }

    return (
        <div>
            {sector === "livinglabs" ?
            <h3 style={{marginTop:"1em", overflow:"hidden", whiteSpace:"no-wrap"}}>
                LivingLab.cloud subscription plans and prices for Living Lab trials
            </h3> :
            <h3 style={{marginTop:"1em", overflow:"hidden", whiteSpace:"no-wrap"}}>
                LLcloud® subscription plans and prices {sector ? `for ${to_sector_name(sector)}` : ""}
            </h3>
            }
            <Offcanvas show={showForm} onHide={()=>{setShowForm(false)}} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Request LLcloud Subscription</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>First name</Form.Label>
                            <Form.Control required ref={nameEl} type="text"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control required ref={surnameEl} type="text"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control required ref={emailEl} type="email"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Company/Organisation</Form.Label>
                            <Form.Control required ref={companyEl} type="text"/>
                        </Form.Group>
                        <Form.Group>    
                            <Form.Label>Choose subscription plan</Form.Label>
                            <Form.Select defaultValue={plan} ref={planEl}>
                                <option value="basic">Basic</option>
                                <option value="standard">Standard</option>
                                <option value="premium">Premium</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>    
                            <Form.Label>Choose subscription duration</Form.Label>
                            <Form.Select value={duration} ref={durationEl} onChange={
                                (e) => {setDuration(e.target.value);}
                            }>
                                <option value="6m">6 months</option>
                                <option value="12m">12 months</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Describe briefly your usecase</Form.Label>
                            <Form.Control as="textarea" rows={3} required ref={usecaseEl}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Which sensors you want to use with LLcloud?</Form.Label>
                            <Form.Control as="textarea" rows={1} required ref={sensorsEl}/>
                        </Form.Group>
                        {
                            <p>Selected integrated platforms: {integrated_string()}</p>
                        }
                        <Form.Group>    
                            <Form.Label>Are you interested in a one month free trial?</Form.Label>
                            <Form.Select defaultValue="no" ref={freeTrialEl}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </Form.Select>
                        </Form.Group>
                        <Button variant="link" target="_blank" href="/documents/LLcloud_Data_Protection_Policy_Basic_Framework_EN.pdf">LLcloud's data protection policy</Button>
                        <Form.Group>
                            <Form.Check required type="checkbox" label="I agree with LLcloud's Data Protection Policy" />
                        </Form.Group>
                        <Button variant="primary" type="submit">Submit subscription request</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <Table striped borderless>
                <thead>
                    <tr>
                        <th className="col-auto">Features \ Plans</th>
                        <th className="col-3">Basic</th>
                        <th className="col-3">Standard</th>
                        <th className="col-3">Premium</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Most suitable for</th>
                        <td>multi-sensor data gathering</td>
                        <td>multi-sensor data gathering, analysis, and visualisation and Living Lab trials</td>
                        <td>complex multi-sensor data gatherings, analysis, and visualisation, and (multiple) Living Lab trials</td>
                    </tr>
                    <tr>
                        <th>Maximum number of trials * </th>
                        <td>1</td>
                        <td>5</td>
                        <td>10 (call for more) </td>
                    </tr>
                    <tr>
                        <th>Cloud data storage (GB) </th>
                        <td>100 GB </td>
                        <td>250 GB  </td>
                        <td>500 GB (call for more)</td>
                    </tr>
                    <tr>
                        <th>Cloud VM RAM (GB) </th>
                        <td>2 GB</td>
                        <td>4 GB</td>
                        <td>8 GB (call for more)</td>
                    </tr>
                    <tr>
                        <th>Cloud VM GPU </th>
                        <td> - </td>
                        <td>on request</td>
                        <td> + </td>
                    </tr>
                    <tr>
                        <th>Dashboard </th>
                        <td>standard</td>
                        <td>standard</td>
                        <td>advanced</td>
                    </tr>
                    <tr>
                        <th>Table/Tree/Map data view </th>
                        <td> + </td>
                        <td> + </td>
                        <td> + </td>
                    </tr>
                    <tr>
                        <th>Data importers</th>
                        <td>some </td>
                        <td>many **</td>
                        <td>all **</td>
                    </tr>
                    <tr>
                        <th>Data processors </th>
                        <td>some </td>
                        <td>many **</td>
                        <td>all **</td>
                    </tr>
                    <tr>
                        <th>Data convertors </th>
                        <td>some </td>
                        <td>many **</td>
                        <td>all **</td>
                    </tr>
                    <tr>
                        <th>Instructions to test users </th>
                        <td> + </td>
                        <td> + </td>
                        <td> + </td>
                    </tr>
                    <tr>
                        <th>Feedback from test users </th>
                        <td> + </td>
                        <td> + </td>
                        <td> + </td>
                    </tr>
                    <tr>
                        <th>Data backup and restore </th>
                        <td> + </td>
                        <td> + </td>
                        <td> + </td>
                    </tr>
                    <tr>
                        <th>Integrated external platforms use </th>
                        <td> - </td>
                        <td>
                            {/* <OverlayTrigger trigger="hover" overlay={
                                <Popover>
                                    <Popover.Header>Integrated platforms</Popover.Header>
                                    <Popover.Body>
                                        <ul>
                                        {integrated_platforms().map(platform => {
                                            return (
                                                <li>{platform}</li>
                                            )
                                        })}
                                        </ul>
                                    </Popover.Body>
                                </Popover>
                            }>
                                <Button>Select 1</Button>
                            </OverlayTrigger> */}
                            <Dropdown onToggle={(nextShow, meta) => {
                                if (nextShow) setStIntegrated({})
                                setStandardShow(nextShow);
                            }}
                            show={standardShow}
                            autoClose="outside"
                            >
                                <Dropdown.Toggle>Select 1</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {integrated_platforms().map(platform => {
                                        return (
                                        <Dropdown.Item onClick={()=>{
                                            let new_integrated = {...stIntegrated};
                                            new_integrated[platform] = !stIntegrated[platform];
                                            setStIntegrated(new_integrated);
                                            let total = 0;
                                            for (let i of integrated_platforms()){
                                                total += new_integrated[i] ? 1 : 0;
                                            }
                                            let max = 1;
                                            if (total == max){
                                                setStandardShow(false);
                                            }
                                        }} active={stIntegrated[platform]}>{platform}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                            {integrated_string_from_plan(stIntegrated)}
                        </td>
                        <td>
                        <Dropdown onToggle={(nextShow, meta) => {
                                if (nextShow) setPrIntegrated({})
                                setPremiumShow(nextShow);
                            }}
                            show={premiumShow}
                            autoClose="outside"
                            >
                                <Dropdown.Toggle>Select 2</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {integrated_platforms().map(platform => {
                                        return (
                                        <Dropdown.Item onClick={()=>{
                                            let new_integrated = {...prIntegrated};
                                            if (platform.startsWith("Movesense") && !prIntegrated[platform]){
                                                for (let i of integrated_platforms()){
                                                    if (prIntegrated[i] && i.startsWith("Movesense")){
                                                        alert("Please select only one movesense integration");
                                                        return;
                                                    }
                                                }
                                            }
                                            new_integrated[platform] = !prIntegrated[platform];
                                            setPrIntegrated(new_integrated);
                                            let total = 0;
                                            for (let i of integrated_platforms()){
                                                total += new_integrated[i] ? 1 : 0;
                                            }
                                            let max = 2;
                                            if (total == max){
                                                setPremiumShow(false);
                                            }
                                        }} active={prIntegrated[platform]}>{platform}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                            {integrated_string_from_plan(prIntegrated)}
                        </td>
                    </tr>
                    {
                        sector === "earthobservation" ?
                            <tr>
                                <th>Price for 12 month plan (EUR) *** </th>
                                <td>330 per month<br />(billed annually) </td>
                                <td>660 per month<br />(billed annually) </td>
                                <td>990 per month<br />(billed annually) </td>
                            </tr> :
                            <tr>
                                <th>Price for 12 month plan (EUR) *** </th>
                                <td>220 per month<br />(billed annually) </td>
                                <td>440 per month<br />(billed annually) </td>
                                <td>660 per month<br />(billed annually) </td>
                            </tr>
                    }
                    {
                        sector === "earthobservation" ?
                            <tr>
                                <th>Price for 6 month plan (EUR) *** </th>
                                <td>400 per month<br />(billed 6 monthly) </td>
                                <td>800 per month<br />(billed 6 monthly) </td>
                                <td>1200 per month<br />(billed 6 monthly) </td>
                            </tr> :
                            <tr>
                                <th>Price for 6 month plan (EUR) *** </th>
                                <td>270 per month<br />(billed 6 monthly) </td>
                                <td>540 per month<br />(billed 6 monthly) </td>
                                <td>810 per month<br />(billed 6 monthly) </td>
                            </tr>
                    }
                      
                    <tr>
                        <th style={{paddingTop:"20px"}}>Get started </th>
                        <td><Button style={{minWidth:"180px", marginTop:"20px"}} varaint="primary" onClick={()=>{openForm("basic")}}>Request subscription or free trial</Button></td>
                        <td><Button style={{minWidth:"180px", marginTop:"20px"}} varaint="primary" onClick={()=>{openForm("standard")}}>Request subscription or free trial</Button></td>
                        <td><Button style={{minWidth:"180px", marginTop:"20px"}} varaint="primary" onClick={()=>{openForm("premium")}}>Request subscription or free trial</Button></td>
                    </tr>
                </tbody>
            </Table>
            <p style={{overflow:"hidden", whiteSpace:"no-wrap"}}>* Maximum number of trials/pilots/projects/data sets/etc. included in subscription plan. </p>
            <p style={{overflow:"hidden", whiteSpace:"no-wrap"}}>** Additional custom data importers/processors/converters can be developed at extra price (TBA). </p>
            <p style={{overflow:"hidden", whiteSpace:"no-wrap"}}>*** No VAT included. </p>
            <p style={{overflow:"hidden", whiteSpace:"no-wrap"}}>For any questions, please <a href="/contact">contact us</a>.</p>
        </div>
    )
}
import React from 'react';
import { Card, Row, Col, Image, Button } from 'react-bootstrap';
import ivo from './images/ivo.png';
import iana from './images/iana.jpg';
import alex from './images/alex.jpg';
import lubo from './images/lubo.jpg';
import stavri from './images/stavri.png';
import nicky from './images/nicky2.png';
import alexander from './images/alexander.png';
import allen from './images/allen.png';
import boris from './images/boris.png';

function Person({image, name, role, role2, linkedin}){
    return (
        <div  style={{textAlign:"center"}}>
            <div><Image height="180px" roundedCircle={true} src={image} /></div>
            <h6 style={{marginTop:"10px"}}>{name}</h6>
            {role ? <div>{role}</div>: null}
            {role2 ? <div>{role2}</div>: null}
            {linkedin ? <Button variant="link" href={linkedin} target="_blank">Linkedin</Button> : null}
        </div>
    )
}

export default function Company({isLivingLab}){
    return (
        <div>
            <h4 style={{marginTop:"60px"}} id="mission">Mission</h4>
            {
                isLivingLab ?
                <div>
                    <p><b>We aim to make LivingLab.cloud the leading and most widely used cloud platform in the world for designing and carrying out Living Lab trials with test users to validate the usefulness, performance and usability of novel mobile apps, sensors, wearables, devices, games, web services, etc. </b></p>
                    <p><b>We want to democratise the process of testing and validating new products in Living Lab trials, by involving their future clients in co-designing and improving them before they are launched to market. </b></p>
                </div> :
                <div>
                    <p><b>We aim to make LLcloud® the leading multi-sensor data cloud in the world, becoming the multi-sensor
        data storage, smart processing and analytics platform of choice for clients and partners in the areas 
        of IoT and sensor networks, wearables, Earth Observation, smart cities, smart farming, smart 
        manufacturing, and more.</b></p>   
                    <p><b>The multi-sensor brain of Planet Earth.</b></p>
                </div>
            }
            

            <h4  style={{marginTop:"70px"}} id="team">Team</h4>
            <div>
                <Row className="justify-content-md-center">
                    <Col xs lg="3"><Person image={stavri} name="Stavri Nikolov, PhD" role="CEO & co-founder" linkedin="https://www.linkedin.com/in/stavri-nikolov-4a97804/"/></Col>
                    <Col xs lg="3"><Person image={ivo} name="Ivo Dilov" role="CTO & co-founder" linkedin="https://www.linkedin.com/in/ivo-d-9b8b07a6/"/></Col>
                    <Col xs lg="3"><Person image={nicky} name="Nickolay Chompalov" role="Legal adviser & co-founder"/></Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col xs lg="3"><Person image={iana} name="Iana Nikolova" role="UI/UX design & wearables sales" linkedin="https://www.linkedin.com/in/iana-nikolova-4b9a25203/"/></Col>
                    <Col xs lg="3"><Person image={lubo} name="Lubo Dilov" role="Full-stack & AI software engineer" /></Col>
                    <Col xs lg="3"><Person image={alex} name="Alex Dimitrov" role="Software developer & data scientist" /></Col>
                </Row>
            </div>
            
            <h4  style={{marginTop:"70px"}} id="advisoryboard">Advisory Board</h4>
            <div>
                <Row className="justify-content-md-center">
                    <Col xs lg="3"><Person image={alexander} name="Alexander Toet, PhD" role="ex Senior Scientist, TNO, The Netherlands" linkedin="https://www.linkedin.com/in/alexandertoet/"/></Col>
                    <Col xs lg="3"><Person image={allen} name="Allen Waxman, PhD" role="Founder at Methane IQ, USA" role2="ex MIT Lincoln Labs Senior Staff" linkedin="https://www.linkedin.com/in/allen-waxman-04b33532/"/></Col>
                    <Col xs lg="3"><Person image={boris} name="Boris Menkov" role="Principal Architect at TomTom, USA" linkedin="https://www.linkedin.com/in/borismenkov/"/></Col>
                </Row>
            </div>

            <h4  style={{marginTop:"70px"}} id="partners">Partners</h4>
            {
                isLivingLab ?
                <div>
                    <h5 style={{marginTop:"20px"}}>Living Labs</h5>
                    <p><a href="https://www.digitalspaces.info/" target="_blank">Digital Spaces Living Lab (DSLL)</a></p>
                    <p>Digital Spaces Living Lab (DSLL) is one of the leading living labs in Europe. In the last 12 years, it has conducted over 45 large-scale technology validation trials in the fields of smart cities, smart agriculture, mobile apps, wearables, location-based services (LBS), games, digital media, etc. It has won several European Living Lab competitions and has participated in many European research and innovation projects in the domains of sustainable wearables, smart cities, new technologies for museums and art galleries.</p>
                    <p>LivingLab.cloud was designed and developed from the ground up by DSLL and has been used by it in over 20 Living Lab trials in the last 3 years. In 2022 LLcloud Ltd was spun off from DSLL to scale up the multi-scale cloud platform and business.</p>
                    <p><b>LivingLab.cloud embodies a decade of experience how to design and perform Living Lab trials of real products in the real world.</b></p>
                    
                    <p><a href="https://galileo-masters.eu/prize/living-lab/" target="_blank">Galileo Living Lab</a></p>
                    <h5 style={{marginTop:"20px"}}>Cloud</h5>
                    <p><a href="https://pcloud.com" target="_blank">pCloud</a> Switzerland</p>
                    <p><a href="https://l3c.cloud/" target="_blank">L3C Cloud</a> UK</p>
                    <p><a href="https://www.stone.bg/en/" target="_blank">Stone Computers</a> Bulgaria</p>
                </div> :
                
                <div>
                    <h5 style={{marginTop:"20px"}}>Cloud</h5>
                    <p><a href="https://pcloud.com" target="_blank">pCloud</a> Switzerland</p>
                    <p><a href="https://l3c.cloud/" target="_blank">L3C Cloud</a> UK</p>
                    <p><a href="https://www.stone.bg/en/" target="_blank">Stone Computers</a> Bulgaria</p>
                    <h5 style={{marginTop:"20px"}}>Wearables</h5>
                    <p><a href="https://wearsustain.eu/" target="_blank">WEAR Sustain project partners and community</a> Europe</p>
                    <p><a href="https://www.movesense.com/" target="_blank">Movesense</a> Finland</p>
                    <h5 style={{marginTop:"20px"}}>IoT</h5>
                    <p><a href="https://cleanspotapp.com/en/index.html" target="_blank">cleanSpot </a> Spain</p>
                    <p><a href="https://airlief.bg/airlief-app/" target="_blank">Airlief</a> Bulgaria</p>
                    <h5 style={{marginTop:"20px"}}>Earth Observation and Smart Cities</h5>
                    <p><a href="https://www.cassini.eu/accelerator/" target="_blank">CASSINI Business Accelerator</a> Europe</p>
                    <p><a href="https://www.cassini.eu/matchmaking/" target="_blank">CASSINI Matchmaking</a> Europe</p>
                    <p><a href="https://homeport.network/" target="_blank">Sfera Technologies</a> Bulgaria</p>
                    <h5 style={{marginTop:"20px"}}>Data Annotation</h5>
                    <p><a href="https://humansintheloop.org/" target="_blank">Humans in the Loop</a></p>
                    <h5 style={{marginTop:"20px"}}>Image and Video Recognition</h5>
                    <p><a href="https://imagga.com/" target="_blank">Imagga</a></p>
                </div>
            }
        </div>
    )
}

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {Form, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

async function loginUser(credentials){
    return fetch('/api/login', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

async function forgotten(email){
    return fetch('/api/forgotten', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email})
    })
        .then(data => data.json());
}

export default function Login({ setToken, onLogin, isLivingLab }) {
    const navigate = useNavigate()
    const emailEl = useRef(null);
    const passwordEl = useRef(null);

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await loginUser(
            {
                email: emailEl.current.value,
                password: passwordEl.current.value
            }
        )
        if (response.err) return alert(response.err);
        if (response.token.userInfo.email === "iddilov@gmail.com"){
            console.log(response.token);
        }
        setToken(response.token);
        if (onLogin) onLogin(navigate);
    }

    const forgetPassword = async () => {
        let email = emailEl.current.value;
        if (!email) return alert("Please enter an email!");
        let response = await forgotten(email);
        if (response.err) return alert(response.err);
        if (response.succ) alert("An email for password reset has been sent.");
    }

    return (
        <div style={{maxWidth: "700px"}}>
            {/*<h3>Due to major server migration LLcloud will not be available from 1am GMT on 8 February 2022 until 6am GMT on 9 February 2022. We apologize for the inconvenience caused!</h3>*/}
            <h2 style={{marginBottom:"10px"}}>Sign in</h2>
            <Form onSubmit = {handleSubmit}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control ref={emailEl} type="email"/>
                </Form.Group>
                <Form.Group style={{marginBottom: "10px"}}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control ref={passwordEl} type="password"/>
                </Form.Group>
                <Button variant="primary" type="submit">Sign in</Button>
                <Button style={{marginLeft: "10px"}} variant="link" onClick={forgetPassword}>Forgot password?</Button>
            </Form>
            {
                isLivingLab ?
                <p>To use and sign in LivingLab.cloud you need to either have an LivingLab.cloud 
                subscription plan (see Pricing to request such a plan) or be invited by 
                someone who does</p> :
                <p>To use and sign in LLcloud you need to either have an LLcloud 
    subscription plan (see Pricing to request such a plan) or be invited by 
    someone who does</p>
            }
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};
export function arrayForPrint (arr) {
    if (!arr) return "";
    let ans = ""
    for (let i=0; i<arr.length; ++i){
        if (i>0) ans += ", ";
        ans += arr[i];
    }
    return ans;
}

export function strToArray (str) {
    if (str === "") return [];
    return str.split(",").map(x => x.trim());
}
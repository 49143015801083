import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import handleError from './handleError';

async function getToken(token){
    return fetch(`/api/gettoken/${token}`).then(data => data.json());
}

export default function GetToken({ setToken }) {
    let { name, token } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        getToken(token)
            .then(data => {
                if (data.err) handleError(data.err, setToken);
                else{
                    console.log(data.token);
                    setToken(data.token);
                }
                navigate(`/trial/${encodeURIComponent(name)}`);
            })
    }, [])

    return (
        <h3>Authenticating...</h3>
    );
}
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Nav, Table, Col, Row, Alert } from 'react-bootstrap';
import Feedback from './feedback';
import handleError from './handleError';
import Instructions from './instructions';
import {arrayForPrint, strToArray} from './utils';
import Data from './data'
import Backup from './backup'
import { useNavigate, useParams } from 'react-router-dom';

async function getTrial(name, token){
    return fetch(`/api/gettrial/${encodeURIComponent(name)}/${token}`).then(data => data.json());
}

async function getUnseen(token, trial_id){
    return fetch("/api/unseen", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({trial_id, token})
    })
        .then(data => data.json());
}

async function getUserList(trial, token){
    return fetch("/api/trialuserlist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({trial, token})
    })
        .then(data => data.json());
}

async function addUser(token, trial, email, privileges){
    return fetch("/api/adduser", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial, email, privileges})
    })
        .then(data => data.json());
}

async function chUser(token, user, trial_id){
    return fetch("/api/chuser", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, user, trial_id})
    })
        .then(data => data.json());
}

async function resendReg(token, user, trial_id){
    return fetch("/api/resendreg", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, user, trial_id})
    })
        .then(data => data.json());
}

async function stravaAuth(token, user, trial_id){
    return fetch("/api/sendstravaauth", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, user, trial_id})
    })
        .then(data => data.json());
}

function userCmp(u1, u2){
    if (u1.admin && !u2.admin) return -1;
    if (!u1.admin && u2.admin) return 1;
    if (u1.name < u2.name) return -1;
    if (u1.name > u2.name) return 1;
    if (u1.surname < u2.surname) return -1;
    if (u1.surname > u2.surname) return 1;
    return 0;
}

function Users({trial, token, setToken}){
    const [users, setUsers] = useState([]);
    const newUserMail = useRef(null);
    const newUserPriv = useRef(null);

    useEffect(() => {
        getUserList(trial, token.token)
            .then(data => {
                if (data.err) handleError(data.err, setToken);
                else setUsers(data.users);
            })
    }, [])

    const addUserToTrial = async event => {
        event.preventDefault();
        const response = await addUser(token.token, trial, newUserMail.current.value, newUserPriv.current.value);
        if (response.err) handleError(response.err, setToken);
        else setUsers(users.concat([response.user]));
    }

    async function changeUser(user){
        const response = await chUser(token.token, user, trial.id);
        if (response.err) return handleError(response.err, setToken);
        if (response.succ) setUsers(users.map(u => u.email===user.email ? {...u, enabled: !user.enabled} : u))
    }

    async function resendRegistration(user){
        const response = await resendReg(token.token, user, trial.id)
        if (response.err) return handleError(response.err, setToken);
        alert("New registration email has been sent");
    }

    async function sendStravaAuth(user){
        const response = await stravaAuth(token.token, user, trial.id);
        if (response.err) return handleError(response.err, setToken);
        alert("Request for strava access has been sent via email");
    }

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Country</th>
                        <th>Type</th>
                        {
                            trial.isAdmin ?
                            <th>Action</th> :
                            null
                        }
                    </tr>
                </thead>
                <tbody>
                    {users.sort(userCmp).map((user, ind) => 
                        <tr key={ind} style={{opacity: user.enabled ? 1 : 0.5}}>
                            <td>{user.email}</td>
                            <td>{user.name}</td>
                            <td>{user.surname}</td>
                            <td>{user.country}</td>
                            <td>{user.admin ? "Admin" : user.guest ? "Guest" : "Test user"}</td>
                            {
                                trial.isAdmin && !user.admin ?
                                <td>
                                    <Button variant="primary" onClick={()=>changeUser(user)}>
                                        {
                                            user.enabled ?
                                            "Remove access" :
                                            "Give access"
                                        }
                                    </Button>
                                    {
                                        user.name ?
                                        null :
                                        <Button variant="primary" onClick={()=>resendRegistration(user)}>Resend registration email</Button>
                                    }
                                    {
                                        trial.sensors && trial.sensors.includes("Strava") && user.name && !user.strava ?
                                        <Button variant="primary" onClick={()=>sendStravaAuth(user)}>Request strava access</Button> :
                                        null
                                    }
                                </td> :
                                null
                            }
                        </tr>    
                    )}
                </tbody>
            </Table>
            {
                trial.isAdmin ? 
                (
                    <Form onSubmit={addUserToTrial}>
                        <Row>
                            <Col>
                                <Form.Control placeholder="user@example.com" type="email" ref={newUserMail}/>
                            </Col>
                            <Col>
                                <Form.Control as="select" ref={newUserPriv}>
                                    <option>Test user</option>
                                    <option>Admin</option>
                                    <option>Guest</option>
                                </Form.Control>
                            </Col>
                            <Col>
                                <Button variant="primary" type="submit">Add user to trial</Button>
                            </Col>
                        </Row>
                    </Form>
                ) :
                null
            }
        </div>
    );
}

async function updateTrial(token, trial){
    return fetch("/api/updatetrial", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial})
    })
        .then(data => data.json());
}

async function deleteTrial(token, trial_id){
    return fetch("/api/deletetrial", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id})
    })
        .then(data => data.json());
}

function TrialSettings({trial, setTrial, token, setToken}){
    const navigate = useNavigate();
    const [name, setName] = useState(trial.name);
    const [description, setDescription] = useState(trial.description);
    const [country, setCountry] = useState(trial.country);
    const [sensors, setSensors] = useState(arrayForPrint(trial.sensors));
    const newSensors = useRef(null);

    const update = async event => {
        event.preventDefault();
        const response = await updateTrial(token.token, {id: trial.id, name, description, country, sensors: strToArray(sensors), newSensors: strToArray(newSensors.current.value)});
        if (response.err) handleError(response.err, setToken);
        else setTrial(response.trial);
    }

    const deleteT = async ()=>{
        if (!window.confirm("Are you sure you want to delete this whole trial? This cannot be undone!")) return;
        const response = await deleteTrial(token.token, trial.id);
        if (response.err) handleError(response.err, setToken);
        else{
            if (response.succ) alert("Trial deleted!");
            setTrial(null);
            if (window.location.host === "digitalspaces.livinglab.cloud"){
                window.location.href = "https://llcloud.eu/dashboard";
            }else{
                navigate("/dashboard");
            }
        }
    }

    return (
        <div>
            <Form onSubmit={update}>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={name} onChange={(event) => {setName(event.target.value)}}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control type="text" value={description} onChange={(event) => {setDescription(event.target.value)}}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control type="text" value={country} onChange={(event) => {setCountry(event.target.value)}}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Sensor list (comma separated)</Form.Label>
                    <Form.Control type="text" value={sensors} readOnly/>
                    <Form.Control type="text" ref={newSensors} placeholder="New sensor 1, New sensor 2"/>
                </Form.Group>
                <Button variant="primary" type="submit">Update trial</Button>
            </Form>
            <Button variant="primary" onClick={deleteT} style={{marginTop:"5px"}}>Delete trial</Button>
        </div>
    )
}

export default function Trial({token, setToken}){
    let { name } = useParams();
    const [trial, setTrial] = useState(undefined);
    const [tab, setTab] = useState({tab: "users"});
    const [unseenFiles, setUnseenFiles] = useState(0);
    const [unseenFeedback, setUnseenFeedback] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        getTrial(name, token.token).then( data => {
            if (data.err) handleError(data.err, setToken);
            else{
                setTrial(data.trial);
                getUnseen(token.token, data.trial.id).then(data => {
                    console.log(data);
                    if (data.unseenFiles) setUnseenFiles(data.unseenFiles);
                    if (data.unseenFeedback) setUnseenFeedback(data.unseenFeedback);
                })
            }
        })
    }, [])
    
    return trial ? (
        <div>
            {
                trial.locked ?
                <Alert>
                    You have reached 90% of your subscription limit. Functionality is limited.
                    Take a look at <Alert.Link href="/pricing">pricing</Alert.Link> to upgrade your plan.
                    Or reach out to us at team@llcloud.eu.
                </Alert>: 
                null
            }
            <Button type="primary" onClick={()=>{navigate(-2)}} style={{float: "right"}}>Back</Button>
            <h1>{trial.name}</h1>
            {
                trial.description ?
                <p>{trial.description}</p> :
                null
            }
            <Nav variant="tabs" activeKey={tab.tab} onSelect={key => setTab({tab: key})}>
                <Nav.Item>
                    <Nav.Link eventKey="users">Users</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="data">
                        Data
                        {
                            unseenFiles ?
                            <span style={{color:"red", marginLeft:"10px"}}>{unseenFiles}</span>:
                            null
                        }
                    </Nav.Link>
                    
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="instructions">Instructions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="feedback">
                        Feedback
                        {
                            unseenFeedback ?
                            <span style={{color:"red", marginLeft:"10px"}}>{unseenFeedback}</span>:
                            null
                        }
                    </Nav.Link>
                </Nav.Item>
                {
                    trial.isAdmin ?
                    <Nav.Item>
                        <Nav.Link eventKey="settings">Settings</Nav.Link>
                    </Nav.Item> :
                    null
                }
                {
                    trial.isAdmin ?
                    <Nav.Item>
                        <Nav.Link eventKey="backup">Backup and restore</Nav.Link>
                    </Nav.Item> :
                    null
                }
            </Nav>
            {tab.tab === "data" ? <Data trial={trial} token={token} setToken={setToken} setTab={setTab} setUnseenFiles={setUnseenFiles}/> : null}
            {tab.tab === "users" ? <Users trial={trial} token={token} setToken={setToken}/> : null}
            {tab.tab === "settings" ? <TrialSettings trial={trial} setTrial={setTrial} token={token} setToken={setToken}/> : null}
            {tab.tab === "instructions" ? <Instructions trial={trial} token={token} setToken={setToken}/> : null}
            {tab.tab === "feedback" ? <Feedback trial={trial} token={token} setToken={setToken} fileList={tab.fileList}  setUnseenFeedback={setUnseenFeedback}/> : null}
            {tab.tab === "backup" ? <Backup trial={trial} token={token} setToken={setToken}/> : null}
        </div>
    ) : (
        <div>
            <h3>Loading trial data...</h3>
        </div>
    );
}
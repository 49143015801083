import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import Jumbotron from './jumbotron';

async function updateUser(credentials){
    return fetch('/api/updateuser', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

async function apiKey(token){
    return fetch('/api/requestapikey', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token})
    })
        .then(data => data.json());
}

export default function Settings({ token, setToken }) {
    const emailEl = useRef(null);
    const nameEl = useRef(null);
    const surnameEl = useRef(null);
    const passwordEl = useRef(null);
    const npasswordEl = useRef(null);
    const npassword2El = useRef(null);
    const companyEl = useRef(null);
    const countryEl = useRef(null);
    let navigate = useNavigate();

    const [name, setName] = useState(token.userInfo.name);
    const [surname, setSurname] = useState(token.userInfo.surname);
    const [country, setCountry] = useState(token.userInfo.country);
    const [company, setCompany] = useState(token.userInfo.company);

    const handleSubmit = async e => {
        e.preventDefault();
        if (npassword2El.current.value !== npasswordEl.current.value) return alert("New passwords do not match!");
        let credentials = {
            email: emailEl.current.value,
            name: nameEl.current.value,
            surname: surnameEl.current.value,
            company: companyEl.current?.value,
            country: countryEl.current.value,
            password: passwordEl.current.value
        }
        if (npasswordEl.current.value) credentials.newPassword = npasswordEl.current.value;
        const response = await updateUser(credentials)
        if (response.err) alert(response.err);
        else{
            setToken(response.token);
            navigate('/dashboard');
        }
    }

    async function getApiKey(){
        const response = await apiKey(token.token);
        if (response.err) alert(response.err);
        else{
            alert(`Your API key is:${response.apiKey}`);
        }
    }

    return (
        <div>
            {
                token.userInfo.plan?.plan ?
                <Jumbotron>
                    <h3>Plan info</h3>
                    <p>Plan: {token.userInfo.plan.plan}</p>
                    <p>Start: {token.userInfo.plan.start}</p>
                    <p>End: {token.userInfo.plan.end}</p>
                    <Button style={{padding:"0px"}} variant="link" href="/pricing">Other plans and pricing</Button>
                    <Button style={{float:"right"}} variant="primary" onClick={()=>{getApiKey()}}>Get API key</Button>
                </Jumbotron> :
                null
            }
            <h2>Update user settings</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control ref={emailEl} type="email" value={token.userInfo.email} readOnly/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={nameEl} type="text" value={name} onChange={(event) => {setName(event.target.value)}}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Surname</Form.Label>
                    <Form.Control ref={surnameEl} type="text" value={surname} onChange={(event) => {setSurname(event.target.value)}}/>
                </Form.Group>
                {
                    token.userInfo.isAdmin ?
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <Form.Control ref={companyEl} type="text" value={company} onChange={(event) => {setCompany(event.target.value)}}/>
                    </Form.Group> :
                    null
                }
                <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control ref={countryEl} type="text" value={country} onChange={(event) => {setCountry(event.target.value)}}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>New password (leave empty to not change)</Form.Label>
                    <Form.Control ref={npasswordEl} type="password"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Confirm new password</Form.Label>
                    <Form.Control ref={npassword2El} type="password"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Current password</Form.Label>
                    <Form.Control ref={passwordEl} type="password"/>
                </Form.Group>
                <Button variant="primary" type="submit">Update</Button>
            </Form>    
        </div>
    );
}
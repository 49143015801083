import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import usa_wells from './images/usa-wells.png';
import usa_wells_texas from './images/usa-wells-texas.png';
import louisiana_map_wells from './images/Louisiana-map-wells.png';
import louisiana_map_wells_details from './images/Louisiana-map-wells-details.png';
import airborne_well_detection from './images/airborne-well-detection.png';
import ms_well_monitoring from './images/ms-well-monitoring.png';

function UseCase({image, body}){
    return (
        <Card style={{margin:"10px"}}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                {body}
            </Card.Body>
        </Card>
    )
}

export default function OilandGasWellsMonitoring(){
    return (
        <div>
            <h3 style={{marginTop:"10px"}}>Multi-Sensor Orphaned/Abandoned Oil & Gas Wells Monitoring Business Solution</h3>

            <p style={{marginTop:"20px", fontSize:"1.1em"}}><b>LLcloud’s multi-sensor orphaned oil & gas wells monitoring solution helps environmental agencies, research labs and NGOs monitor methane and other emissions from abandoned oil & gas wells using a variety of sensor data. It aids real-estate and insurance companies track the progress of well plugging and land regeneration programs in areas with many abandoned wells and monitor land and house prices there.</b></p>

            <h5 id="usecases" style={{marginTop:"50px"}}>Orphaned / Abandoned Oil & Gas Wells in the USA</h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={usa_wells} body={
                        <div>
                            <Card.Text>
                             There are over 115000 known orphaned wells in 27 American states for which data is available in the  <a href="https://www.sciencebase.gov/catalog/item/62ebd67bd34eacf539724c56" target="_blank">United States Documented Unplugged Orphaned Oil and Gas Well (DOW) dataset</a>. It is estimated that there are hundreds of thousands of undocumented orphaned wells in the USA that need to be located and some researchers think they may be as many as 2-3 million.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={usa_wells_texas} body={
                        <div>
                            <Card.Text>
                            Example of the orphaned wells locations from the DOW dataset in the state of Texas.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>


            <h5 id="usecases" style={{marginTop:"50px"}}>Orphaned Oil & Gas Wells Locations and Views from Above</h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={louisiana_map_wells} body={
                        <div>
                            <Card.Text>
                             Orphaned wells in the state of Louisiana near New Orleans (Maxar satellite image at 50cm resolution). Data from OpenAerialMap. Well location and info from DOW dataset.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={louisiana_map_wells_details} body={
                        <div>
                            <Card.Text>
                            Close-up of the orphaned wells near New Orleans in the state of Louisiana and their views (same Maxar satellite image).
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>



            <h5 style={{marginTop:"50px"}}>Orphaned Oil & Gas Wells Data Integration</h5>
            <p>LLcloud’s multi-sensor orphaned oil & gas wells monitoring solution supports integration of a multitude of detection, monitoring and analytics capabilities, which include:</p>
            <ul>
                <li><p>Ground-based cameras, LiDARs, in-place IOT sensors and wearable data ingestion, visualization and analysis;</p></li>

                <li><p>The ability to ingest drone and piloted aircraft imagery in GeoTIFF format and other data for airborne well detection and monitoring; </p></li>

                <li><p>AI trained well-area detector that can suggest possible unknown well locations based on airborne and satellite image analysis.</p></li>
            </ul>

            <h5 id="usecases" style={{marginTop:"50px"}}>Multi-Scale Multi-Sensor Orphaned Well Detection and Monitoring </h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={airborne_well_detection} body={
                        <div>
                            <Card.Text>
                            Our solution supports ingestion, visualsation and analysis of airborne (drone, UAV, piloted aircraft) data for well detection and monitoring.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={ms_well_monitoring} body={
                        <div>
                            <Card.Text>
                             All such multi-sensor data can be jointly displayed, fused and analysed in our solution to obtain more accurate well emission and status results.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>


            <h5 style={{marginTop:"50px"}}>LLcloud's Multi-Sensor Well Monitoring Main Features</h5>
            <ul>
                <li><p>Ingest and integrate multiple sensor system data streams and text & image reports from the field to the cloud.</p></li>

                <li><p>Cloud-based processing supported, 3rd party processing chains hosted.</p></li>

                <li><p>Geospatial visualisation of data & information on the internet via standard web browsers.</p></li>

                <li><p>Track all phases of orphan well application (detection, assessments, plugging, contracting, and follow-on inspections).</p></li>
            </ul>



            <h5 style={{marginTop:"50px"}}>LLcloud's Multi-Sensor Well Monitoring Main Benefits</h5>
            <ul>
                <li><p>No software needs to be installed on your servers nor physical infrastructure in the field to monitor orphaned oil & gas wells and obtain analytics, as it is a cloud solution working globally.</p></li>

                <li><p>For environmental agencies, easily customise the solution with your own state and county boundaries, maps and other zones in a matter of hours.</p></li>

                <li><p>Save time and money having accurate up-to-date multi-sensor well analytics for different zones, counties, states and countries.</p></li>

                <li><p>Automate your administrative processes with reports and statistics generated automatically, showing the well status, emissions, most recent developments, etc.</p></li>

                <li><p>If you already have additional sensors (airborne or ground-based) in the well area, add data from them to your monitoring solution to obtain a more comprehensive and up-to-date picture of what is going on. </p></li>

            </ul>


            <h5 style={{marginTop:"50px"}}>Pricing</h5>
            <p>If you are an environmental agency, research lab, NGO, or a real-estate or insurance company, who needs to monitor orphaned oil and gas wells emissions and their status, please <a href="/contact">contact us</a> describing your needs.</p>
        </div>
    )
}

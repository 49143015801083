import React from 'react';

// Appears as https://llcloud.eu/about
export default function About(){
    return (
        <div>
            <p style={{marginTop:"2em"}}>
            <b>LLcloud® - the multi-sensor cloud,</b> is a cloud software platform for automated multi-sensor data gathering, analysis and visualisation.
            </p>

            <p><b>The LLcloud® multi-sensor cloud platform helps developers, who need to use multi-sensor data in their services and apps, to reduce the time and money they must spend to bring such data into one common data space and in compatible formats, for joint multi-sensor data visualisation, analysis and fusion and making better decision, based on all available data. LLcloud bridges the gaps between various data silos and software platforms.</b></p>

            <p><b>LLcloud® provides many ready-made cloud data importers, converters and processors.</b></p>
            <p><b>Data importers</b> allow automated data upload from smartphone sensors (via the <a href="https://phyphox.org/" target="_blank">Phyphox</a> app) and location tracks (via the <a href="https://www.strava.com/" target="_blank">Strava</a> app), smartwatches, geo-tagged photos (via the photoSpot app for <a href="https://play.google.com/store/apps/details?id=com.eym.photospotapp" target="_blank">Android</a> and <a href="https://apps.apple.com/us/app/photospotapp/id1539906598#?platform=iphone" target="_blank">iOS</a>), GoPro cameras, Air Quality (AQ) and other IoT sensors, drones, satellite images and other Copernicus Sentinel data from the <a href="https://dataspace.copernicus.eu/" target="_blank">Copernicus Data Space Ecosystem</a> portal, ships, trucks (coming up in Q3 2024), etc.</p>
            <p><b>Data converters</b> transform data to other common formats, such as .KML, .GPX, .CSV, .GeoJSON, .GeoTIFF, etc.</p>
            <p><b>Data processors</b> analyse the gathered multi-sensor data directly in LLcloud. Some data processors allow measuring global and local similarity between location tracks; finding track segments with bad or missing GNSS data; defining geofences and zones and checking location against them; global real-time vessel tracking and movement analysis; calculating exposure along a track to various air pollutants measured by ground-based stations, critical infrastructure and emissions monitoring and
analytics like ports, large tents, pipelines or orphaned gas and oil wells, etc. Others implement communication with various wearable and fixed devices via WiFi, 5G, LoRaWAN, WebSocket, etc. LLcloud processors can be combined in data processing pipelines.</p>
            <p><b>LLcloud® smart visualisation tools</b> show various multi-sensor data views - table, tree, map-based, detailed view. Layers of different geo-located data can be visualised and analysed simultaneously.</p>
            <p><b>The same platform supports carrying out complex Living Lab trials</b> by sending trial instructions to test users and collecting user feedback. Other features supported include data-related feedback and discussions, screenshots, live photo/video feedback sharing and comments.</p>

            <p><b>If you are building an app or service where you need to gather and analyse new multi-sensor data sets or/and combine your own data with such from other sources and platforms using LLcloud, </b>please submit a subscription plan request for <a href="/pricing/earthobservation">Earth Observation</a>, <a href="/pricing/iot">IoT sensors or networks</a> or <a href="/pricing/wearables">wearables</a>.</p>
            <p>For any other uses of LLcloud or questions you have, please <a href="/contact">contact us</a>.</p>
        </div>
    )
}

import React, { useState } from 'react';
import { Carousel, Row, Col, Button } from 'react-bootstrap';
import img1 from './images/img1.jpg';
import img2 from './images/img2.jpg';
import img3 from './images/img3.jpg';
import img4 from './images/img4.jpg';
import img5 from './images/img5.JPG';
import img6 from './images/img6.jpg';
import img7 from './images/img7.jpeg';
import img8 from './images/img8.jpg';
import img9 from './images/img9.jpg';
import img10 from './images/img10.png';

function Tocsen(){
    return (
        <p style={{margin: "auto", textAlign: "center"}}>
            Living Lab trial of <a href="https://en.tocsen.com/" target="_blank">Tocsen crash sensor</a> with mountain bikers.
        </p>
    )
}

function Shyn(){
    return (
        <p style={{margin: "auto", textAlign: "center"}}>
            Living Lab trial with mountain guides of the <a href="https://shyn.blue/" target="_blank">Shyn sensor</a> - the world's most advanced positioning wearable.
        </p>
    )
}

function ARmap(){
    return (
        <p style={{margin: "auto", textAlign: "center"}}>
Living Lab trial of <a href="https://www.artemapp.com/" target="_blank">Arte Mapp</a> which allows users to build AR-assisted smart terrain surfaces using only their smartphone.        </p>
    )
}

function Dynaback(){
    return (
        <p style={{margin: "auto", textAlign: "center"}}>
        Living Lab trial of <a href="https://dynaback-tshirt.com/" target="_blank">Dynaback smart garment</a> which prevents back pain and reminds the wearer to be active.
        </p>
    )
}

function Hackwear(){
    return (
        <p style={{margin: "auto", textAlign: "center"}}>
            Hack & Wear Hackathon for novel wearable devices.
        </p>
    )
}

export default function Gallery(){
    const [index, setIndex] = useState(0);

    return (
        <div className="container">
            <Carousel activeIndex={index} onSelect={(newIndex, e) => {setIndex(newIndex)}}>
                <Carousel.Item>
                    <img className="d-block w-100" src={img1}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={img2}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={img3}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block" style={{width: "90%", margin: "auto"}} src={img7}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={img8}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img style={{display: "inline", width: "30%"}} src={img9}/>
                    <img style={{display: "inline", width: "70%"}} src={img10}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block" style={{width: "90%", margin: "auto"}} src={img4}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block" style={{width: "90%", margin: "auto"}} src={img6}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block" style={{width: "92%", margin: "auto"}} src={img5}/>
                </Carousel.Item>
            </Carousel>
            {
                index < 3 ?
                <Tocsen/> :
                null
            }{
                index == 3 || index == 4 ?
                <Shyn/> :
                null
            }{
                index == 5 ?
                <ARmap/> :
                null
            }{
                index == 6 || index == 7 ?
                <Dynaback/> :
                null
            }{
                index == 8 ?
                <Hackwear/> :
                null
            }
        </div>
    )
}
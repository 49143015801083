import getDistance from 'geolib/es/getDistance';

function shuffle(arr, k){
    if (k === undefined) k = arr.length
    for (let i=0; i<k; ++i){
        let other = i + Math.floor(Math.random()*(arr.length-i));
        [arr[i], arr[other]] = [arr[other], arr[i]]
    }
}

function countIn(centers, positions, r){
    let count = 0;
    for (let pos of positions){
        for (let center of centers){
            let dist = getDistance({lon: pos.Longitude, lat: pos.Latitude}, center)
            if (dist < r){
                count++;
                break;
            }
        }
    }
    return count;
}

let spotBeams = {}

spotBeams.getRandom = (shipPositions, k, r) => {
    shuffle(shipPositions, k)
    let centers = []
    for (let i=0; i<k; ++i){
        centers.push({lon: shipPositions[i].Longitude, lat: shipPositions[i].Latitude})
    }
    let count = countIn(centers, shipPositions, r)
    return {count, centers}
}

spotBeams.getReasonable = (shipPositions, k, r) => {
    shuffle(shipPositions)
    let centers = []
    for (let position of shipPositions){
        let isInCircle = false
        for (let center of centers){
            if (getDistance({lon: position.Longitude, lat: position.Latitude}, center) < r){
                center.cnt++;
                isInCircle = true
                break;
            }
        }
        if (!isInCircle){
            centers.push({lon: position.Longitude, lat: position.Latitude, cnt: 0})
        }
    }
    centers.sort((x, y) => y.cnt - x.cnt)
    centers = centers.slice(0, k)
    let count = countIn(centers, shipPositions, r)
    return {count, centers}
}

export default spotBeams
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Gallery from './gallery';
import airlief from './images/airlief.png';
import arte from './images/arte.png';
import city from './images/city.png';
import farm from './images/farm.png';
import ships from './images/anwerpen-region-vessel-tracking-map.png';

function UseCase({image, body}){
    return (
        <Card style={{margin:"10px"}}>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                {body}
            </Card.Body>
        </Card>
    )
}

export default function Iot(){
    return (
        <div>
            <h3 style={{marginTop:"10px"}}>LLcloud® for IoT</h3>
            <h5 style={{marginTop:"20px"}}>
            What Makes LLcloud Special for Designing, Developing and Testing IoT Sensors and Networks            
            </h5>
            <ul>
                <li><p>LLcloud is a platform designed and implemented specifically for multi-sensor data gathering, analysis and visualisation in the cloud.</p></li>
                <li><p>It is one of the few platforms which allows joint analysis of ground wearable and fixed sensor data.</p></li>
                <li><p>LLcloud offers an extremely high level of automation of multi-sensor data import, conversion, organisation and processing in the cloud, thus saving considerable time and money.</p></li>
                <li><p>LLcloud can be used for both data gathering from novel space and ground data solutions and applications, but also for Living Lab trials with test users, who can then provide feedback via the same platform. </p></li>
                <li><p>LLcloud subscription plans come with a large and fast increasing collection of cloud data importers, convertors and processors for IoT and smart city apps, which can be combined in many ways</p></li>
                <li>
                    <p><b>LLcloud features for IoT, logistics and smart city applications include:</b></p>
                    <ul>
                        <li><p>Advance geofences, zones, tracking, etc.</p></li>
                        <li><p>Communication with various IoT sensors and devices via WiFi, 5G and LoRaWAN</p></li>
                        <li><p>Real-time tracking and movement analysis of ships (via AIS stations) and trucks (coming soon)</p></li>
                    </ul>
                </li>
                <li>
                    <p><b>LLcloud integrations for IoT include:</b></p>
                    <ul>
                        <li><p><a target="_blank" href="https://www.strava.com/">Strava</a> integration (for routes and location data);</p></li>
                        <li><p><a target="_blank" href="https://phyphox.org/">Phyphox</a> integration (for smarphone sensor data);</p></li>
                        <li><p><a target="_blank" href="https://gopro.com/">GoPro</a> integration (for GoPro videos and telemetry data)</p></li>
                        <li><p>Global ship location and movement (via AIS stations)</p></li>
                        <li><p>DJI drone video and telemetry integration – coming soon</p></li>
                    </ul>
                </li>
            </ul>
            <h5 id="usecases" style={{marginTop:"50px"}}>Selected Customer and Partner Use Cases</h5>
            <div>
                <Row>
                    <Col>
                    <UseCase image={airlief} body={
                        <div>
                            <Card.Text>
                            <a href="https://airlief.com/" target="_blank">Airlief</a> - studies of air quality exposure of cyclists, runners, and walkers in Sofia, Bulgaria, along different routes in the city (using their Strava tracks), based on the readings of the nearest ground-based air quality (AQ) stations. 
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={arte} body={
                        <div>
                            <Card.Text>
An international team of aerospace engineers (<a href="https://artemapp.com/"  target="_blank">ArteMapp</a>) developed an affordable and practical terrain mapping solution that enables fast and frequent surveying of a selected area by merely walking with a mobile device. They used LLcloud for data gathering and topographical model computation and visualisation.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <UseCase image={city} body={
                        <div>
                            <Card.Text>
                            A large European network of architects and urbanists are using LLcloud for placemaking research and exploration in many major European cities and for in-situ multi-sensor data gathering of geo-located data (photo, audio, video, notes) to create new city stories and tours. 
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                    <Col>
                    <UseCase image={ships} body={
                        <div>
                            <Card.Text>
                            Global real-time vessel tracking and movement analysis in LLcloud via Automatic Identification System (AIS) station data is used by some of our clients and partners, together with other IoT and Earth Observation multi-sensor data. The example shows tracking of vessels in the Antwerp area.
                            </Card.Text>
                        </div>
                    }/>
                    </Col>
                </Row>
            </div>
            <h5 style={{marginTop:"50px"}}>Pricing</h5>
            <p><b>If you are designing, developing or testing new IoT sensors or networks, or a novel logistics application or service and want to rapidly do this at a much lower cost, try and use LLcloud by </b><a href="/pricing/iot">submitting a subscription plan request</a>.</p>
        </div>
    )
}
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Jumbotron } from 'react-bootstrap';
import handleError from './handleError';
import date_format from './date_format';

async function getBackupList(token, trial_id){
    return fetch("/api/backuplist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, trial_id})
    })
        .then(data => data.json());
}

function createBackup(token, trial_id, setToken, backups, setBackups){
    let host = window.location.host === 'localhost:3000' ? 'localhost:3001' : window.location.host;
    let url = `${window.location.protocol==='https:' ? 'wss' : 'ws'}://${host}/api/backup`;
    const ws = new WebSocket(url);
    ws.onopen = event=>{
        ws.send(JSON.stringify({token, trial_id}))
        alert("Backup process might take a few minutes. Press OK to continue.");
    }
    ws.onmessage = event => {
        let data = JSON.parse(event.data);
        if (data.err){
            handleError(data.err, setToken);
        }else{
            setBackups([data.backup].concat(backups))
            alert(`Backup ${data.backup.foldername} has been successfully created.`)
        }
        ws.close();
    }
}

function restore(token, trial_id, folderid, setToken){
    let host = window.location.host === 'localhost:3000' ? 'localhost:3001' : window.location.host;
    let url = `${window.location.protocol==='https:' ? 'wss' : 'ws'}://${host}/api/restore`;
    const ws = new WebSocket(url);
    ws.onopen = event=>{
        ws.send(JSON.stringify({token, trial_id, folderid}))
        alert("Restore process started... this might take a few minutes.");
    }
    ws.onmessage = event => {
        let data = JSON.parse(event.data);
        if (data.err){
            handleError(data.err, setToken);
        }else{
            alert("Restore completed!");
        }
        ws.close();
    }
}

export default function Backup( {trial, token, setToken} ){
    const [backups, setBackups] = useState([]);

    useEffect(()=>{
        getBackupList(token.token, trial.id)
            .then(data => {
                console.log(data);
                if (data.err) return handleError(data.err, setToken);
                setBackups(data.backups);
            })
    }, [])

    function restoreBackup(folderid, foldername){
        if (!window.confirm(`Are you sure you want to restore from ${foldername}? All current data will be lost!`)) return;
        restore(token.token, trial.id, folderid, setToken);
    }

    return (
        <div>
            <Button style={{float: "right"}} variant="primary" onClick={()=>{createBackup(token.token, trial.id, setToken, backups, setBackups)}}>Create new manual backup</Button>
            <h2>Available backups of trial</h2>
            <div>
                {
                    backups.sort((b1, b2) => (new Date(b2.created) - new Date(b1.created))).map(b => (
                        <div>
                            <Button variant="link" onClick={()=>{restoreBackup(b.folderid, b.foldername)}}>{b.foldername}</Button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
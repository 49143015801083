import React, {useEffect, useRef, useState} from 'react';
import { Button, Table, Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Jumbotron from './jumbotron'
import handleError from './handleError';
import Trial from './trial';
import {arrayForPrint, strToArray} from './utils';

async function getTrialList(token){
    return fetch("/api/triallist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token})
    })
        .then(data => data.json());
}

async function createTrial(token, name, description, country, sensors){
    return fetch("/api/createtrial", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, name, description, country, sensors})
    })
        .then(data => data.json());
}

function TrialList({token, setToken, setTrial}){
    const [trials, setTrials] = useState([]);
    const [newTrialForm, setNewTrialForm] = useState(false);
    const newTrialName = useRef(null);
    const newTrialDescription = useRef(null);
    const newTrialCountry = useRef(null);
    const newTrialSensors = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        getTrialList(token.token)
            .then(data => {
                if (data.err) handleError(data.err, setToken);
                else setTrials(data.trials);
            })
    }, []);

    const insertTrial = async event => {
        event.preventDefault();
        if (token.userInfo.plan?.maxTrials && token.userInfo.plan.maxTrials <= trials.filter(t => t.isOwner).length){
            if(window.confirm("You have reached your number of trials limit. You can delete some trials or upgrade your plan. Do you want to take a look at pricing options?")){
                navigate("/pricing");
            }
            return;
        }
        if (token.userInfo.plan?.maxTrials && ( new Date(token.userInfo.plan.start) > new Date() || new Date(token.userInfo.plan.end) < new Date())){
            alert(`Your plan is not currently active. Your plan is active from ${token.userInfo.plan.start} to ${token.userInfo.plan.end}`);
            return;
        }
        const response = await createTrial(token.token, newTrialName.current.value, newTrialDescription.current.value, newTrialCountry.current.value, strToArray(newTrialSensors.current.value));
        if (response.err) handleError(response.err, setToken);
        else {
            setTrials(trials.concat([response.trial]));
            setNewTrialForm(false);
            alert(`${response.trial.name} successfully added! You can enter the trial from the table.`);
        }
    }

    return (
        <div>
            <h2>Trials</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {
                            token.userInfo.isAdmin && trials.length>0 && trials[0].company ?
                            <th>Owner</th> :
                            null
                        }
                        <th>Name</th>
                        <th>Description</th>
                        <th>Sensors</th>
                        {
                            token.userInfo.isAdmin && trials.length>0 && trials[0].company ?
                            <th>Plan</th> :
                            null
                        }
                    </tr>
                </thead>
                <tbody>
                    {trials.sort((f, s) => {
                        if (f.company && s.company && f.company != s.company) {
                            if (f.company === "dsll") return 1
                            if (s.company === "dsll") return -1;
                            return f.company.toLowerCase() < s.company.toLowerCase() ? -1 : 1
                        }
                        return f.name.toLowerCase() < s.name.toLowerCase() ? -1 : 1
                    }).map( (trial, ind) => (
                        <tr style={{cursor: "pointer"}} key={ind} onClick={()=>{window.location.href = `${trial.address}trial/${encodeURIComponent(trial.name)}/${token.token}`}}>
                            {
                                token.userInfo.isAdmin && trials.length>0 && trials[0].company ?
                                <td>{trial.company}</td> :
                                null
                            }
                            <td>{trial.name}</td>
                            <td>{trial.description}</td>
                            <td>{arrayForPrint(trial.sensors)}</td>
                            {
                                token.userInfo.isAdmin && trials.length>0 && trials[0].company ?
                                <td>{trial.plan}</td> :
                                null
                            }
                        </tr>
                    ))}
                </tbody>
            </Table>
            {
                token.userInfo.isAdmin || token.userInfo.plan?.maxTrials ?
                (
                    newTrialForm ?
                    <Jumbotron>
                        <Button variant="primary" style={{float: "right"}} onClick={()=>{setNewTrialForm(false)}}>Back</Button>
                        <h3>New trial</h3>
                        <Form onSubmit={insertTrial}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control placeholder="Trial name" type="text" ref={newTrialName}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control placeholder="Trial description" type="text" ref={newTrialDescription}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Country</Form.Label>
                                <Form.Control defaultValue={token.userInfo.country} placeholder="Bulgaria" type="text" ref={newTrialCountry}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Sensor list (comma separated)</Form.Label>
                                <Form.Control placeholder="Phyphox, GoPro" type="text" ref={newTrialSensors}/>
                            </Form.Group>
                            <Button variant="primary" type="submit">Add trial</Button>
                        </Form>
                    </Jumbotron> :
                    <Button variant="primary" onClick={()=>{setNewTrialForm(true)}}>Add new trial</Button>
                ) :
                null
            }
        </div>
    )
}

export default function Dashboard({token, setToken}) {
    const [trial, setTrial] = useState(null);

    return  (
        <TrialList token={token} setToken={setToken} setTrial={setTrial}/>
    );
}
import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

async function question(data){
    console.log(data);
    return fetch("/api/askquestion", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(data => data.json());
}

export default function Contact(){
    const emailEl = useRef(null);
    const nameEl = useRef(null);
    const surnameEl = useRef(null);
    const companyEl = useRef(null);
    const questionEl = useRef(null);
    const [submitted, setSubmitted] = useState(false);

    async function handleSubmit(e){
        e.preventDefault();
        let question_data ={
            name: nameEl.current.value,
            surname: surnameEl.current.value,
            email: emailEl.current.value,
            company: companyEl.current.value,
            question: questionEl.current.value
        }
        let result = await question(question_data);
        if (result.err){
            alert("Something went wrong, please try again!");
        }else{
            setSubmitted(true);
        }
    }

    return (
        <div>
            <h3>Contact</h3>
            {submitted ?
            <p>Your question or request has been received by LLcloud's team and we shall respond to it soon.</p> :
            <div>
            <p>If you have any questions about using LLcloud, or want to get in touch with us, please fill in the form below or send an email to <a href="mailto:team@llcloud.eu">team@llcloud.eu</a>.</p>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>First name</Form.Label>
                        <Form.Control required ref={nameEl} type="text"/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Last name</Form.Label>
                        <Form.Control required ref={surnameEl} type="text"/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control required ref={emailEl} type="email"/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Company/Organisation</Form.Label>
                        <Form.Control required ref={companyEl} type="text"/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Ask us anything</Form.Label>
                        <Form.Control as="textarea" rows={8} required ref={questionEl}/>
                    </Form.Group>
                    <Button variant="link" target="_blank" href="documents/LLcloud_Data_Protection_Policy_Basic_Framework_EN.pdf">LLcloud's data protection policy</Button>
                    <Form.Group>
                        <Form.Check required type="checkbox" label="I agree with LLcloud's Data Protection Policy above" />
                    </Form.Group>
                    <Button variant="primary" type="submit">Contact us</Button>
            </Form>
            </div>
            }
        </div>
    )
}
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import qs from 'qs';
import DataProtection from './dataprotection';

async function registerUser(credentials){
    return fetch('/api/register', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

export default function Register({ setToken }) {
    const emailEl = useRef(null);
    const nameEl = useRef(null);
    const surnameEl = useRef(null);
    const passwordEl = useRef(null);
    const password2El = useRef(null);
    const companyEl = useRef(null);
    const countryEl = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    let params = qs.parse(location.search, {ignoreQueryPrefix: true});

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await registerUser(
            {
                email: emailEl.current.value,
                name: nameEl.current.value,
                surname: surnameEl.current.value,
                registerToken: params.token,
                company: companyEl.current?.value,
                country: countryEl.current.value,
                password: passwordEl.current.value,
                password2: password2El.current.value
            }
        )
        if (response.err) alert(response.err);
        else{
            console.log(response.token);
            setToken(response.token);
            navigate('/dashboard');
        }
    }

    return (
        <div>
            <h2>Registration</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control ref={emailEl} type="email" value={params.email} readOnly/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control required ref={nameEl} type="text"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Surname</Form.Label>
                    <Form.Control required ref={surnameEl} type="text"/>
                </Form.Group>
                {
                    params.company === "true" ?
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <Form.Control required ref={companyEl} type="text"/>
                    </Form.Group> :
                    null
                }
                <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control ref={countryEl} type="text"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control required ref={passwordEl} type="password"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control required ref={password2El} type="password"/>
                </Form.Group>
                <DataProtection/>
                <Form.Group>
                    <Form.Check required type="checkbox" label="I agree with LLcloud's Data Protection Policy above" />
                </Form.Group>
                <Button variant="primary" type="submit">Register</Button>
            </Form>    
        </div>
    );
}

Register.propTypes = {
    setToken: PropTypes.func.isRequired
};
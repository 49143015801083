import React from 'react';

export default function SmartCities(){
    return (
        <div>
            <h3>LLcloud® for Smart Cities</h3>
            <p>
                Based on over 10 years of experience with developing and testing novel smart 
city services, apps, sensors and devices, including over 40 large-scale 
technology trials in various cities across Europe, in the beginning of 2022 we 
launched a new solution called ‘<b>LLcloud® for Smart Cities</b>’ to address the needs
of modern smart cities around the world.
            </p>
            <p>
            <b>LLcloud® for Smart Cities</b> uses the same advanced multi-sensor data gathering,
processing and visualisation technology behind LLcloud®, but bringing it to a new 
level and scale, dealing with the big data operations of smart cities and 
combining the needs city authorities have to select, pilot, validate and deploy 
various novel software and hardware solutions in their cities, and the 
requirements of developers of such services, to access multi-sensor data from 
various sources (both open data and other), in order to build and offer efficient 
such services.
            </p>
            <p>
            <b>LLcloud® for Smart Cities</b> allows the direct validation of novel city services and 
apps by supporting Living Lab trials with citizens, organising pilots to validate 
them, sending instructions to test users and gathering feedback from them, and 
in general involving the people who live in a city in the co-design, improvements 
and adoption of such services.
            </p>
            <p>
            <b>LLcloud® for Smart Cities</b> is offered to city authorities as PaaS (Platform as a 
Service), including tens of ready-made multi-sensor data importers from ground-
based (IoT sensor networks, wearable sensors, etc) and aerial data sensors 
(drones and satellites), data processors that can run on tens to hundreds of VMs 
(Virtual Machines) in the cloud, and advanced data analysis and visualisation 
tools.
            </p>
            <p>
            Example applications and use cases of <b>LLcloud® for Smart Cities</b> include: air 
pollution monitoring and predictions from ground-based and wearable AQI 
sensors, city infrastructure monitoring and analysis, cycling networks planning 
and use, urban green space management, city farming, recycling and the 
circular economy, placemaking and city tours, etc
            </p>
            <p>
            <b>LLcloud® for Smart Cities</b> is already integrated with many other data platforms 
and mobile apps, but new integrations with existing city software infrastructure, 
services (via APIs) and databases, can be created upon request.
            </p>
            <p>
            If you want to try and use <b>LLcloud® for Smart Cities</b> in your city, please <a href="/contact">contact us</a> describing your needs.</p>
        </div>
    )
}
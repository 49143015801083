import React from 'react';
import { Table } from 'react-bootstrap';
import Jumbotron from './jumbotron';
import Highlight from 'react-highlight';
import '../node_modules/highlight.js/styles/atom-one-light.css'

function ApiSection({section}){
    return (
        <Jumbotron>
            <h3>{section.title}</h3>
            <p>{section.description}</p>
            <Table hover>
                <tbody>
                    <tr>
                        <td>Available at</td>
                        <td>{section.available}</td>
                    </tr>
                    <tr>
                        <td>Request type</td>
                        <td>{section.type}</td>
                    </tr>
                    { section.request ?
                        <tr>
                            <td>Request body (JSON)</td>
                            <td>
                                <pre>
                                    <code>
                                        <Highlight className='javascript'>
                                            {section.request}
                                        </Highlight>
                                    </code>
                                </pre>
                            </td>
                        </tr> :
                        null
                    }
                    { section.response ?
                        <tr>
                            <td>Response body (on success)</td>
                            <td>
                                <pre>
                                    <code>
                                        <Highlight className='javascript'>
                                            {section.response}
                                        </Highlight>
                                    </code>
                                </pre>
                            </td>
                        </tr> : null
                    }
                    <tr>
                        <td>Example code</td>
                        <td>
                            <pre>
                                <code>
                                    <Highlight className='javascript'>
                                        {section.code}
                                    </Highlight>
                                </code>
                            </pre>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Jumbotron>
    )
}

export default function Api(){
    let address = "https://api.llcloud.eu";
    return (
        <div className='container'>
            <h3>LLcloud® public API</h3>
            <p>
            LLcloud’s public API is available at <a href="https://api.llcloud.eu/">https://api.llcloud.eu/</a>. It’s a RESTful API and provides basic functionalities – login, listing files and instructions and uploading data. All request and response bodies are JSON formatted. For more specific information please look at the example source code provided.
            </p>
            <ApiSection
                section={{
                    title:"Login",
                    available:"/login",
                    type:"POST",
                    request:
`{
    "email": "A valid email specifying the user who logs in",
    "password": "The corresponding password (since the communication is over https this is secure)"
}`,
                    response:
`{
    "userInfo": "An object containing information about the user - email, name, surname, etc.",
    "token": "A token used for authentication valid for 30 minutes"
}`,
                    code:
`let {userInfo, token} = await fetch("${address}/login", {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email, password})
}).then(res => res.json());`
                }}
            />

<ApiSection
                section={{
                    title:"List trials",
                    available:"/listtrials/:token",
                    type:"GET",
                    request: undefined,
                    response:
`{
    "trials": "An array of trials (containing names used for other operations)"
}`,
                    code:
`let {trials} = await fetch(\`${address}/listtrials/\${token}\`).then(res => res.json())`
                }}
            />

<ApiSection
                section={{
                    title:"List files",
                    available:"/listfiles/:token/:trial_name",
                    type:"GET",
                    request: undefined,
                    response:
`{
    "files": "An array of file metadatas"
}`,
                    code:
`let {files} = await fetch(\`${address}/listfiles/\${token}/\${trialName}\`).then(res => res.json())`
                }}
            />

<ApiSection
                section={{
                    title:"List instructions",
                    available:"/listinstructions/:token/:trial_name",
                    type:"GET",
                    request: undefined,
                    response:
`{
    "instructions": "An array of instructions containing names and download links"
}`,
                    code:
`let {instructions} = await fetch(\`${address}/listinstructions/\${token}/\${trialName}\`).then(res => res.json())`
                }}
            />

<ApiSection
    section={{
        title:"Upload file",
        available:"/upload",
        type:"POST",
        request:
`{
    "token": "An authentication token from login",
    "trial_name": "Trial name for which to upload file",
    "sensor": "A string describing the sensor used to acquire the file (ex. Camera)",
    "file": "The file content encoded in base64",
    "extension": "The file extension (ex. jpg)"
}`,
        response:
`{
    "user": "test@example.com",
    "sensor": "Phonecamera",
    "filename": "demo-trial-TesTes 2021-6-23 3-5-59.jpg",
    "modified": "Wed, 23 Jun 2021 00:05:59 +0000",
    "size": 36388,
    "fileid": 4720696584
}`,
        code:
`
let file = await fs.readFile(filepath);
file = file.toString('base64') //it's important to encode the file content as a base64 string
let file = await fetch("${address}/upload", {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({file, extension, sensor, token, trial_name})
}).then(res => res.json())
`
   }}
/>

<ApiSection
    section={{
        title:"Download file",
        available:"/download/:token/:fileid",
        type:"GET",
        request: undefined,
        response:
`{
    "link": "A link from where to download a zip archive containing the file"
}`,
        code:
`let response = await fetch(\`${address}/download/\${token}/\${fileid}\`).then(res => res.json())`
   }}
/>
<ApiSection
    section={{
        title:"Delete file",
        available:"/delete",
        type:"POST",
        request:
`{
    "token": "An authentication token from login",
    "fileid": "A fileid part of the file metadata of the file to be deleted"
}`,
        response: undefined,
        code:
`let response = await fetch("${address}/delete", {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({fileid, token, trial_name})
})`
   }}
/>
        </div>
    )
}